import React from 'react'

const AutoRemovePolicyPage: React.FC = (): JSX.Element => {
  return (
    <section className="bg-secondaryDarkGrey  w-full p-4 rounded-xl border-pallet2 border">
      <div className="p-8 w-full mx-auto  rounded shadow">
        <h2 className="text-xl font-bold mb-4">Política de Autoexclusão</h2>
        <p className="mb-4">
          Caso sinta que não é mais capaz de controlar seu hábito de apostas ou fazê-lo de forma segura, oferecemos a opção
          de autoexclusão das apostas conosco. Para iniciar o período de resfriamento, conforme detalhado abaixo, visite
          nossa página de Jogo Responsável.
        </p>

        <div>
          <h3 className="text-lg font-bold mb-2">Condições e Processos</h3>
          <p className="mb-4">
            A autoexclusão significa parar de fazer apostas ou jogar por um período determinado. A autoexclusão pode ser
            temporária ou permanente.
          </p>
          <p className="mb-4">
            Ao solicitar um dos períodos de autoexclusão oferecidos, você concorda com os seguintes termos e condições, que
            entrarão em vigor a partir do momento em que receber um e-mail de confirmação da autoexclusão escolhida. Este
            e-mail será enviado apenas para o endereço de e-mail utilizado no registro da sua conta e mostrará a data de
            conclusão da autoexclusão.
          </p>

          <p className="mb-4">
            Solicitações feitas via nosso sistema de chat não serão processadas. No entanto, um agente de suporte ao cliente
            o orientará para o serviço de e-mail de suporte ao cliente.
          </p>

          <p className="mb-4">
            Após o recebimento de uma solicitação por e-mail para o fechamento da conta, a conta solicitada e quaisquer
            contas vinculadas serão colocadas na primeira etapa de resfriamento de 24 horas, que desativará sua conta,
            impedindo qualquer atividade de jogo. Uma vez que esse período tenha passado, você receberá um e-mail para
            confirmar a duração desejada da autoexclusão, que pode ser de 1 dia, 1 semana, 1 mês, 3 meses, 6 meses, 1 ano ou
            permanente. Não haverá variação desses períodos de tempo. Se não recebermos uma resposta dentro de mais 24
            horas, sua conta será reaberta.
          </p>

          <p className="mb-4">
            Caso, após revisar nossas opções de autoexclusão, você opte por uma autoexclusão permanente, será necessário
            definir um período de autoexclusão quando solicitado. Em alguns casos, poderemos decidir colocar uma conta em um
            breve período de autoexclusão, se acreditarmos que seja necessário e benéfico para o cliente. Isso será
            comunicado por e-mail, e os termos e condições acima se aplicarão.
          </p>

          <p className="mb-4">
            Durante um período de autoexclusão, você não está autorizado a deixar qualquer saldo na sua conta. Não será
            possível acessar sua conta para sacar fundos durante o período de autoexclusão solicitado. Se tiver uma conta
            afetada pela autoexclusão que contenha apostas ativas, a função de Saque será aplicada no momento da confirmação
            da autoexclusão. As apostas não serão anuladas, os valores apostados não serão devolvidos nem permanecerão
            ativos durante o período de autoexclusão. As apostas não serão restabelecidas após o término da autoexclusão.
            Enquanto estiver autoexcluído, você não terá direito a nenhum benefício, bônus ou recargas.
          </p>

          <p className="mb-4">
            Uma vez aplicada a autoexclusão à sua conta, ela permanecerá em vigor até o final do período solicitado e não
            será revertida ou diminuída por qualquer motivo. As autoexclusões permanentes permanecerão fechadas
            indefinidamente.
          </p>

          <p className="mb-4">
            Faremos todo o possível para garantir o cumprimento de nossa política de autoexclusão de jogo responsável. No
            entanto, você reconhece que não seremos responsáveis se você tentar abrir uma nova conta ou obtiver sucesso.
            Além disso, não seremos responsáveis se você continuar a depositar e apostar usando contas adicionais que não
            foram previamente divulgadas. Quaisquer perdas incorridas durante o período de autoexclusão não serão
            reembolsadas.
          </p>

          <p className="mb-4">
            Se você agir em desacordo com uma solicitação de autoexclusão, retemos o direito de encerrar ou anular quaisquer
            apostas que você tenha feito e tomar outras medidas apropriadas. Além disso, se tivermos suspeitas ou se uma
            investigação adicional fornecer evidências de que você tentou ativamente disfarçar a origem da sua conta ou
            contas, o que afeta nossa capacidade de bloquear sua conta ou contas prontamente, a empresa reserva o direito de
            encerrar ou anular quaisquer apostas que você tenha feito e tomar outras medidas apropriadas.
          </p>

          <p className="mb-4">
            Assim que o período de autoexclusão escolhido chegar ao fim, sua conta será automaticamente reaberta. No
            entanto, você receberá uma confirmação por e-mail dessa informação, que será enviada para o seu endereço de
            e-mail registrado.
          </p>
        </div>
      </div>
    </section>
  )
}

export default AutoRemovePolicyPage
