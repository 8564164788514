import React, { createContext, useState, useContext, ReactNode, useLayoutEffect } from 'react'

// Define the shape of the context
interface HeaderContextProps {
  isHeaderVisible: boolean
  toggleHeaderVisibility: () => void
  isDropdownOpen: boolean
  toggleDropdown: () => void
  closeDropdown: () => void
}

// Create the context with undefined as the initial value
const HeaderContext = createContext<HeaderContextProps | undefined>(undefined)

// Provider component to wrap the application or parts of it
export const HeaderProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isHeaderVisible, setIsHeaderVisible] = useState(false)
  const [isDropdownOpen, setIsDropdownOpen] = useState(() => {
    const initialDropdownState = window.innerWidth >= 1024
    console.log('Initial isDropdownOpen:', initialDropdownState)
    return initialDropdownState
  })

  // Toggle header visibility
  const toggleHeaderVisibility = () => {
    setIsHeaderVisible((prev) => {
      const newState = !prev
      console.log('Toggling isHeaderVisible:', newState)
      return newState
    })
  }

  // Toggle dropdown visibility
  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => {
      const newState = !prev
      console.log('Toggling isDropdownOpen:', newState)
      return newState
    })
  }

  // Close dropdown
  const closeDropdown = () => {
    console.log('Closing dropdown')
    setIsDropdownOpen(false)
  }

  useLayoutEffect(() => {
        console.log('useLayoutEffect')
    // const handleResize = () => {
    //   if (window.innerWidth < 1024) {
    //     console.log('Window resized, setting isDropdownOpen to false')
    //     setIsDropdownOpen(false)
    //   } else {
    //     console.log('Window resized, setting isDropdownOpen to true')
    //     setIsDropdownOpen(true)
    //   }
    // }

    // handleResize() // Check screen size on initial load
    // console.log('Initial window size:', window.innerWidth)

    // window.addEventListener('resize', handleResize)
    // return () => {
    //   window.removeEventListener('resize', handleResize)
    // }
  }, [])

  return (
    <HeaderContext.Provider
      value={{
        isHeaderVisible,
        toggleHeaderVisibility,
        isDropdownOpen,
        toggleDropdown,
        closeDropdown,
      }}
    >
      {children}
    </HeaderContext.Provider>
  )
}

// Hook to use the header context
export const useHeader = (): HeaderContextProps => {
  const context = useContext(HeaderContext)
  if (!context) {
    console.error('useHeader must be used within a HeaderProvider')
    throw new Error('useHeader must be used within a HeaderProvider')
  }
  console.log('useHeader context:', context)
  return context
}
