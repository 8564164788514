import React from 'react'

const Moeda: React.FC<React.SVGProps<SVGSVGElement>> = (): JSX.Element => {
  return (
    <svg id="Camada_1" data-name="Camada 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
      <path
        style={{ fill: '#fff16b', strokeWidth: '0px' }}
        d="m32,57.98c-14.32,0-25.98-11.65-25.98-25.98S17.68,6.02,32,6.02s25.98,11.65,25.98,25.98-11.65,25.98-25.98,25.98Z"
      />
      <path
        style={{ fill: '#ffd400', strokeWidth: '0px' }}
        d="m57.98,32c0-14.32-11.65-25.98-25.98-25.98v51.95c14.32,0,25.98-11.65,25.98-25.98Z"
      />
      <path
        style={{ fill: '#ffd400', strokeWidth: '0px' }}
        d="m32,51.79c-10.91,0-19.79-8.88-19.79-19.79s8.88-19.79,19.79-19.79,19.79,8.88,19.79,19.79-8.88,19.79-19.79,19.79Z"
      />
      <path
        style={{ fill: '#ff9f00', strokeWidth: '0px' }}
        d="m51.79,32c0-10.91-8.88-19.79-19.79-19.79v39.57c10.91,0,19.79-8.88,19.79-19.79Z"
      />
      <path
        style={{ fill: '#fff16b', strokeWidth: '0px' }}
        d="m36.48,29.07l-3.29-4.11c-.61-.76-1.77-.76-2.38,0l-3.29,4.11-4.99-2.49c-1.13-.57-2.42.42-2.17,1.66l2.03,10.15c.14.71.77,1.22,1.49,1.22h16.24c.73,0,1.35-.51,1.49-1.22l2.03-10.15c.25-1.24-1.04-2.23-2.17-1.66l-4.99,2.49Z"
      />
      <path
        style={{ fill: '#ffd400', strokeWidth: '0px' }}
        d="m40.12,39.61c.73,0,1.35-.51,1.49-1.22l2.03-10.15c.25-1.24-1.04-2.23-2.17-1.66l-4.99,2.49-3.29-4.11c-.3-.38-.75-.57-1.19-.57v15.22h8.12Z"
      />
    </svg>
  )
}

export default Moeda
