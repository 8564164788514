import React, { useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import jogarButtonIcone from '@/assets/icones/button-jogar.png'
// import jogarGratisButtonIcone from '@/assets/icones/button-jogar-gratuitamente.png'
import Modal from '@/components/atoms/Modal'

// Define the props for the component
interface ModalSearchProps {
  isOpen: boolean
  onRequestClose: () => void
  filteredData: Array<{ url: string; title: string; game_url: string }>
  position?: { top: number; left: number; width: number } // Optional prop for dynamic position
}

const ModalSearch: React.FC<ModalSearchProps> = ({ isOpen, onRequestClose, filteredData, position }) => {
  console.log('ModalSearch rendered with isOpen:', isOpen)
  console.log('Position:', position)
  console.log('Filtered data:', filteredData)

  // UseEffect to lock/unlock scrolling
  useEffect(() => {
    console.log('useEffect triggered with isOpen:', isOpen, 'and position:', position)
    if (isOpen && position) {
      // Lock scrolling when the modal has a defined position
      console.log('Locking body scroll')
      document.body.style.overflow = 'hidden'
    } else {
      console.log('Unlocking body scroll')
      document.body.style.overflow = ''
    }
    return () => {
      console.log('Cleaning up: unlocking body scroll on component unmount or props change')
      document.body.style.overflow = ''
    }
  }, [isOpen, position])

  // Function to replace spaces with hyphens in URLs
  const replaceSpacesWithHyphensInURL = (url: string): string => {
    const formattedURL = url.replace(/ /g, '-').toLowerCase()
    console.log('Formatted URL:', formattedURL)
    return formattedURL
  }

  if (!isOpen) {
    console.log('Modal is not open, returning null')
    return null
  }

  // Style for position, applies absolute style only if position is provided
  const positionStyle: React.CSSProperties = position
    ? { position: 'absolute', top: position.top, left: position.left, width: position?.width || 'auto' }
    : {}

  console.log('Position style:', positionStyle)

  return (
    <Modal
      isOpen={isOpen}
      closeModal={onRequestClose}
      size="large"
      border="blue"
      nopadding={false}
      fullScreenWithMargin={!position} // Set fullScreenWithMargin to false if position is present
      usePosition={!!position}
      positionStyle={positionStyle} // Pass position style to Modal
    >
      <div className="p-4">
        <h2 className="text-xl font-bold mb-4">Resultados da Pesquisa</h2>
        <div className="grid grid-cols-3 md:grid-cols-5 gap-4">
          {filteredData.map((game, index) => (
            <div key={index} className="relative group">
              <img
                className="w-full border border-sky-500/50 transition-all block rounded-2xl"
                src={game.url}
                alt="Image art of game"
              />
              {game.title !== 'Em breve' && (
                <div className="absolute inset-0 flex flex-col items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity">
                  <NavLink to={`/gaming/${replaceSpacesWithHyphensInURL(game.title)}`} className="mb-2">
                    <img src={jogarButtonIcone} alt="Jogar" className="w-20" />
                  </NavLink>
                  {/* <NavLink to={`/gaming/${replaceSpacesWithHyphensInURL(game.title)}`}>
                    <img src={jogarGratisButtonIcone} alt="Jogar Gratuitamente" className="w-20" />
                  </NavLink> */}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </Modal>
  )
}

export default ModalSearch
