import React, { useEffect, useRef, useState } from 'react'
import { NavLink } from 'react-router-dom'

import bcashDourada from '@/assets/icones/LOGO-ECLIPSE-SIDEBAR-PC.png'

import { CardGames } from '@/interfaces/application/cardGames'
import useDevice from '@/service/device.hook'
import 'swiper/css'
import 'swiper/css/pagination'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper/modules' // Certifique-se de importar o módulo de navegação
import top6 from '@/assets/top10-banner/6.png'
import top7 from '@/assets/top10-banner/7.png'
import top8 from '@/assets/top10-banner/8.png'
import top9 from '@/assets/top10-banner/9.png'

type Props = {
  newData: CardGames[]
}

const Top10Games: React.FC<Props> = ({ newData }): JSX.Element => {
  const [getMaxSlides, setMaxSlides] = useState<number>(7)
  const [newCardActive, setNewCardActive] = useState<number | null>(null)
  const { isMobile } = useDevice()
  const swiperRefNew = useRef({} as any) // Novo swiper ref
  const maxSlideDesktop = 5
  const maxSlideMobile = 3
  const replaceSpacesWithHyphensInURL = (url: string): string => url.replace(/ /g, '-').toLowerCase()

  useEffect(() => {
    const maxSlides = isMobile ? maxSlideMobile : maxSlideDesktop
    setMaxSlides(maxSlides)
  }, [isMobile, maxSlideDesktop, maxSlideMobile])




  return (
    <div>
      <div className="text-white flex flex-wrap items-center justify-between gap-2  text-base rounded-full border-[1px] bg-secondaryDarkGrey border-blue-300/30 my-custom-margin2 ">
        <div className="flex gap-2 pl-1 items-center justify-center flex-shrink-0">
          <span className="w-7 h-4 ml-1">
            <img src={bcashDourada} alt="Exclusivos" />
          </span>
          <span className="flex font-bold text-sm md:text-base">Top 10 Mae.bet</span>
        </div>

        <div className="flex h-[30px] md:h-[40px]">
          <div className="cursor-pointer new-review-swiper-button-prev rounded-tl-[24px] text-sm rounded-bl-[24px] flex-1 flex items-center bg-accent h-full w-[46px] justify-center ">
            <svg
              fill="#fff"
              height={20}
              id="Camada_1"
              data-name="Camada 1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 64 64"
            >
              <path
                className="cls-1"
                d="m7.15,32L27.48,11.67l6.78,6.78-9.04,9.04h31.62v9.04h-31.62s9.04,9.04,9.04,9.04l-6.78,6.78L7.15,32Z"
              />
            </svg>
          </div>
          <div className="cursor-pointer new-review-swiper-button-next rounded-tr-[24px] text-sm rounded-br-[24px] flex-1 flex items-center bg-accent h-full w-[46px] justify-center">
            {' '}
            <svg
              fill="#fff"
              height={20}
              className="text-white"
              data-name="Camada 1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 64 64"
            >
              <path
                className="cls-1 text-white"
                d="m56.85,32l-20.33-20.33-6.78,6.78,9.04,9.04H7.15v9.04h31.62l-9.04,9.04,6.78,6.78,20.33-20.33Z"
              />
            </svg>
          </div>
        </div>
      </div>

      <div className="w-full my-custom-margin">
        <Swiper
          modules={[Navigation]} // Incluir o módulo de navegação
          spaceBetween={10}
          centeredSlides={false}
          slidesPerView={getMaxSlides}
          pagination={false}
          ref={swiperRefNew}
          onReachBeginning={() => {}}
          onReachEnd={() => {}}
          loop={true}
          //   autoplay={isMobile ? { delay: 2000 } : {}}
          navigation={{
            nextEl: '.new-review-swiper-button-next',
            prevEl: '.new-review-swiper-button-prev',
          }}
        >
          {newData.map((image, index) => (
            <SwiperSlide
              key={index}
              onMouseLeave={() => setNewCardActive(null)}
              onMouseOver={() => setNewCardActive(typeof image.id === 'number' ? image.id : null)}
              onTouchStart={() => setNewCardActive(typeof image.id === 'number' ? image.id : null)}
              className="swiper-slide-custom swipper-top-10"
              style={{ zIndex: newCardActive === image.id ? 10 : 1 }}
            >
              <NavLink
                to={`/gaming/${replaceSpacesWithHyphensInURL(image.title)}`}
                className="flex items-center justify-center mt-0 mb-0 sm:mb-4 nav-link-limited"
              >
                <img
                  className={`swipper-top10-img object-contain transition-all cover ${
                    newCardActive && image.id === newCardActive && 'opacity-20'
                  } block rounded-2xl`}
                  src={image.url}
                  alt="Image art of game"
                />
              </NavLink>
              {/* {image.id === newCardActive && image.title !== 'Em breve' && (
                <div className="absolute inset-0 left-0 right-0 bottom-0 top-0 w-full h-full flex flex-col items-center justify-center z-20">
                  <NavLink
                    to={`/gaming/${replaceSpacesWithHyphensInURL(image.title)}`}
                    className="flex items-center justify-center mb-4"
                  >
                    <img src={jogarButtonIcone} alt="Jogar" className="button-game" />
                  </NavLink>
                  <NavLink
                    to={`/gaming/${replaceSpacesWithHyphensInURL(image.title)}`}
                    className="flex items-center justify-center"
                  >
                    <img src={jogarGratisButtonIcone} alt="Jogar Gratuitamente" className="button-game" />
                  </NavLink>
                </div>
              )} */}
            </SwiperSlide>
          ))}

          <SwiperSlide
            key={6}
            className="swiper-slide-custom swipper-top-10"
            style={{ zIndex: newCardActive === 6 ? 10 : 1 }}
          >
            <img
              className={`swipper-top10-img  object-contain transition-all cover mt-0 sm:mt-0 ${
                newCardActive && 6 === newCardActive && 'opacity-20'
              } block rounded-2xl`}
              src={top6}
              alt="Image art of game"
            />
          </SwiperSlide>

          <SwiperSlide
            key={7}
            className="swiper-slide-custom swipper-top-10"
            style={{ zIndex: newCardActive === 7 ? 10 : 1 }}
          >
            <img
              className={`swipper-top10-img  object-contain transition-all cover mt-0 sm:mt-0 ${
                newCardActive && 7 === newCardActive && 'opacity-20'
              } block rounded-2xl`}
              src={top7}
              alt="Image art of game"
            />
          </SwiperSlide>

          <SwiperSlide
            key={8}
            className="swiper-slide-custom swipper-top-10"
            style={{ zIndex: newCardActive === 8 ? 10 : 1 }}
          >
            <img
              className={`swipper-top10-img  object-contain transition-all cover mt-0 sm:mt-0 ${
                newCardActive && 8 === newCardActive && 'opacity-20'
              } block rounded-2xl`}
              src={top8}
              alt="Image art of game"
            />
          </SwiperSlide>

          <SwiperSlide
            key={9}
            className="swiper-slide-custom swipper-top-10"
            style={{ zIndex: newCardActive === 9 ? 10 : 1 }}
          >
            <img
              className={`swipper-top10-img  object-contain transition-all cover mt-0 sm:mt-0 ${
                newCardActive && 9 === newCardActive && 'opacity-20'
              } block rounded-2xl`}
              src={top9}
              alt="Image art of game"
            />
          </SwiperSlide>

        </Swiper>
      </div>
      
    </div>
  )
}

export default Top10Games
