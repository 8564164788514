export enum ITranslateKeys {
  'customHeader_signin' = 'customHeader_signin',
  'customHeader_signout' = 'customHeader_signout',
  'customHeader_signup' = 'customHeader_signup',
  'customHeader_premium' = 'customHeader_premium',
  'modalSignup_title' = 'modalSignup_title',
  'modalSignup_subtitle' = 'modalSignup_subtitle',
  'modalSignup_enter' = 'modalSignup_enter',
}

export type ITranslateMessages = {
  customHeader_signin: ITranslateKeys
  customHeader_signout: ITranslateKeys
  customHeader_signup: ITranslateKeys
  customHeader_premium: ITranslateKeys
  modalSignup_title: ITranslateKeys
  modalSignup_subtitle: ITranslateKeys
  modalSignup_enter: ITranslateKeys
}

type ITranslateItem = {
  id: string
  defaultMessage: string
  message?: string
}

export type ITranslateDictionary = {
  [key in ITranslateKeys]: ITranslateItem
}
