import { AppState } from './state'

export const setAppData = (appData: Partial<AppState>) => ({
  type: 'app/setAppData',
  payload: appData,
})

export const resetAppData = () => ({
  type: 'app/resetAppData',
})
