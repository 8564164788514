import React, { useEffect } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { useForm, SubmitHandler } from 'react-hook-form'
import FormInput from '@/components/atoms/FormInput'
import FormButton from '@/components/atoms/FormButton'
import { ButtonType } from '@/interfaces/application/buttonType'
import IconEmail from '@/components/atoms/Icons/Email'
import IconUser from '@/components/atoms/Icons/UserLarge'
import IconPadlock from '@/components/atoms/Icons/Padlock'
import useApiService from '@/service/api.hook'
import { useSelector } from 'react-redux'
import { RootState } from '@/store/store'
import { UserState } from '@/store/user/state'
import { toast } from 'react-toastify'

type FormData = {
  login: string
  email: string
  password?: string
  phone: string
}

const AccountInfoPage: React.FC = (): JSX.Element => {
  const { updateUser, resendVerifyEmail } = useApiService()
  const schema = Yup.object().shape({
    login: Yup.string().required('Campo obrigatório').required('Campo obrigatório'),
    email: Yup.string().email('E-mail inválido').required('Campo obrigatório'),
    password: Yup.string(),
    phone: Yup.string().required('Campo obrigatório'),
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<FormData>({
    resolver: yupResolver(schema),
  })

  const userData: UserState = useSelector((state: RootState) => state.user)

  useEffect(() => {
    setValue('login', userData.login)
    setValue('email', userData.email)
    setValue('phone', userData.phone)
  }, [userData, setValue])

  const handleFormSubmit: SubmitHandler<FormData> = async (data) => {
    updateUser(data)
      .then(() => {
        console.log('success')
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const handleVerifyEmail = () => {
    resendVerifyEmail().then((result) => {
      if (!result.error) {
        toast.success(
          'Enviamos um e-mail de confirmação para a sua conta de e-mail. Verifique sua conta de e-mail e também na pasta de SPAM!'
        )
      } else {
        toast.error('Não foi possivél enviar o email de verificação')
      }
    })
  }

  return (
    <section className="bg-secondaryDarkGrey  w-full p-4 rounded-xl">
      <div>
        <h1 className="pb-1 text-xl font-medium">Informações da conta</h1>
      </div>
      <form className="mt-6 space-y-4 " onSubmit={handleSubmit(handleFormSubmit)}>
        <div className="flex flex-col lg:flex-row items-center gap-4">
          <FormInput
            register={register}
            errors={errors}
            required
            name="email"
            label="Email"
            placeholder="Insira o seu e-mail"
            icon={<IconEmail />}
          />
          <FormInput
            register={register}
            errors={errors}
            name="password"
            label="Senha"
            type="password"
            placeholder="Digite uma senha"
            icon={<IconPadlock />}
          />
        </div>
        <div className="flex flex-col lg:flex-row items-center gap-4">
          <FormInput
            register={register}
            errors={errors}
            required
            name="login"
            label="Nome de usuário"
            placeholder="Insira o seu nome de usuário"
            icon={<IconUser />}
          />
          <FormInput
            register={register}
            errors={errors}
            required
            name="phone"
            label="Telefone para contato"
            placeholder="00 0 0000 0000"
          />
        </div>
        <div className="pt-4 flex justify-center lg:justify-start gap-8">
          <FormButton
            title="Salvar"
            type="submit"
            className="w-[90%] lg:w-[200px] uppercase font-medium rounded-full"
            buttonType={ButtonType.Confirm}
          />
          {!userData.emailVerifiedAt && (
            <FormButton
              title="Verificar Email"
              type="submit"
              onClick={handleVerifyEmail}
              className="w-[90%] lg:w-[200px] uppercase font-medium rounded-full"
              buttonType={ButtonType.Deposit}
            />
          )}
        </div>
      </form>
    </section>
  )
}

export default AccountInfoPage
