import React, { useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { RootState } from '@/store/store'
import DatePicker from 'react-datepicker'
import { formatCurrency } from '@/lib/currency'
import IconEyeFill from '@/components/atoms/Icons/EyeFill'
import IconEyeSlashFill from '@/components/atoms/Icons/EyeSlashFill'

interface Props {
  register: any
  errors: any
  required?: boolean
  readOnly?: boolean // Corrigido aqui
  name: string
  className?: string
  type?: string
  value?: string | Date | boolean
  control?: any
  icon?: JSX.Element
  formatType?: 'text' | 'text-upper' | 'money-brl' | 'cep' | 'card' | 'number' | 'date:MM/yyyy' | 'date:dd/MM/yyyy' | 'cpf'
  label?: string | JSX.Element
  placeholder?: string
  maxLength?: number
  autoComplete?: string
  onChange?: (value: any) => void
}

const FormInput: React.FC<Props> = ({
  register,
  errors,
  required,
  readOnly, // Corrigido aqui
  name,
  control,
  className,
  type = 'text',
  icon,
  formatType = 'text',
  value,
  label,
  placeholder,
  maxLength,
  autoComplete,
  onChange,
}: Props): JSX.Element => {
  const isError = errors[name]
  const [checked, setChecked] = useState(false)
  const [showPassword, setShowPassword] = useState(false) // State for toggling password visibility
  const locale = useSelector((state: RootState) => state.app.locale)
  const [localValue, setLocalValue] = useState<string | Date>()

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) onChange(e)
    setChecked(e.target.checked)
  }

  const FormatMask = {
    'money-brl': (value: string) => {
      const numericValue = parseInt(value.replace(/[^0-9]/g, ''))
      const formattedValue = `R$ ${formatCurrency(numericValue)}`
      return formattedValue
    },
    text: (value: string) => value,
    'text-upper': (value: string) => value.toUpperCase(),
    number: (value: string) => value.replace(/[^0-9]/g, '').slice(0, maxLength), // Limitação para números
    cep: (value: string) => {
      let cep = value.replace(/\D/g, '')
      cep = cep.replace(/^(\d{5})(\d)/, '$1-$2')

      return cep
    },
    card: (value: string) => {
      const valueClean = value.replace(/[^0-9]/g, '')
      let formattedValue = valueClean.replace(/(\d{4})/g, '$1 ')

      formattedValue = formattedValue.trim()
      return formattedValue
    },
    'date:MM/yyyy': (value: string) => {
      const numericString = value.replace(/[^0-9]/g, '')
      const month = numericString.slice(0, 2)
      const year = numericString.slice(2)
      let formattedDate = ``

      if (month && month !== '') {
        formattedDate += month
      }
      if (year && year !== '') {
        formattedDate += `/${year}`
      }
      return formattedDate
    },
    'date:dd/MM/yyyy': (value: string) => {
      const numericString = value.replace(/[^0-9]/g, '')
      const day = numericString.slice(0, 2)
      const month = numericString.slice(2, 4)
      const year = numericString.slice(4)
      let formattedDate = ``

      if (day && day !== '') {
        formattedDate += day
      }
      if (month && month !== '') {
        formattedDate += `/${month}`
      }
      if (year && year !== '') {
        formattedDate += `/${year}`
      }
      return formattedDate
    },
    cpf: (value: string) => {
      const numericValue = value.replace(/\D/g, '')
      let formattedValue = numericValue
      if (numericValue.length > 3) {
        formattedValue = `${numericValue.slice(0, 3)}.${numericValue.slice(3)}`
      }
      if (numericValue.length > 6) {
        formattedValue = `${formattedValue.slice(0, 7)}.${numericValue.slice(6)}`
      }
      if (numericValue.length > 9) {
        formattedValue = `${formattedValue.slice(0, 11)}-${numericValue.slice(9)}`
      }
      return formattedValue
    },
  }

  useEffect(() => {
    if (typeof value === 'boolean') {
      setChecked(value)
    } else if (typeof value === 'string') {
      const formattedValue = FormatMask[formatType](value)
      setLocalValue(formattedValue)
    } else if (value) {
      const formattedValue = FormatMask[formatType](new Date(value).toDateString())
      setLocalValue(formattedValue)
    }
  }, [value, type, formatType])

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e && e.target) {
      const inputValue = e.target.value
      if (inputValue !== undefined) {
        const formattedValue = FormatMask[formatType](inputValue).slice(0, maxLength)
        setLocalValue(formattedValue)
        e.target.value = formattedValue
        if (onChange) onChange(e)
      }
    }
  }

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev)
  }

  return (
    <div className={`w-full space-y-1 ${className}`}>
      <div className={`space-y-1 ${type === 'checkbox' && 'flex flex-row-reverse items-center justify-end'}`}>
        <label htmlFor={name} className={`block text-xs font-thin ml-2 ${type === 'checkbox' ? '' : 'mb-2 '}`}>
          {label}
          {required && <span className="text-red-500">*</span>}
        </label>
        <div className="relative">
          {icon && <div className="absolute left-3 top-1/2 transform -translate-y-1/2">{icon}</div>}
          {type === 'date' ? (
            <Controller
              control={control}
              name={name}
              defaultValue={localValue}
              render={({ field }) => (
                <div className="bg-[#0f1923] rounded-md border border-[#344056]">
                  <DatePicker
                    placeholderText={placeholder}
                    id={name}
                    onChange={(date: Date) => {
                      field.onChange(date)
                    }}
                    locale={locale}
                    selected={field.value}
                    dropdownMode="select"
                    showMonthDropdown
                    showYearDropdown
                    dateFormat={formatType.replace('date:', '')}
                    adjustDateOnChange
                    wrapperClassName="w-full"
                    autoComplete="off"
                    disabled={readOnly}
                    className="block text-sm p-2 w-full focus:outline-none text-white bg-[#0f1923] rounded-md"
                    onChangeRaw={(e) => handleInputChange(e)}
                    customInput={
                      <input
                        type="text"
                        maxLength={maxLength}
                        id={name}
                        disabled={readOnly}
                        onChange={(e) => {
                          handleInputChange(e)
                        }}
                        value={field.value || ''}
                        className={`text-sm py-2 px-4 w-full focus:outline-none text-white bg-[#0f1923] rounded-md ${
                          icon ? 'pl-10' : ''
                        }`}
                        placeholder={placeholder}
                      />
                    }
                  />
                </div>
              )}
            />
          ) : type === 'checkbox' ? (
            <div className="flex items-center space-x-2 cursor-pointer ">
              <label
                htmlFor={name}
                className={`relative cursor-pointer flex items-center justify-center w-5 h-5 bg-[#484c54] border border-[#3498db] rounded-full`}
              >
                <input
                  type="checkbox"
                  id={name}
                  className="absolute opacity-0 cursor-pointer "
                  required={required}
                  disabled={readOnly}
                  {...register(name, { default: false })}
                  checked={checked}
                  onChange={handleCheckboxChange}
                  autoComplete={autoComplete}
                />
                {checked && (
                  <div
                    className={`w-3 h-3 transform bg-[#3498db] cursor-pointer scale-100 transition-transform ease-in-out duration-300 rounded-full`}
                  ></div>
                )}
              </label>
            </div>
          ) : (
            <div>
              <input
                maxLength={maxLength}
                id={name}
                type={type === 'password' && showPassword ? 'text' : type}
                value={localValue}
                disabled={readOnly}
                {...register(name)}
                autoComplete={autoComplete}
                onChange={(e) => {
                  handleInputChange(e)
                }}
                className={`text-sm py-2 px-4 focus:outline-none text-white bg-[#0f1923] rounded-md border border-[#344056] ${
                  type === 'checkbox' ? '' : 'w-full'
                } ${icon ? 'pl-10' : ''}`}
                placeholder={placeholder}
              />
              {type === 'password' && (
                <div
                  className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
                  onClick={togglePasswordVisibility}
                >
                  {showPassword ? <IconEyeSlashFill /> : <IconEyeFill />}
                </div>
              )}
            </div>
          )}
        </div>

        {isError && (
          <div>
            <p className="text-red-500 text-xs mt-1">{errors[name].message}</p>
          </div>
        )}
      </div>
    </div>
  )
}

export default FormInput
