import * as crypto from 'crypto-js'

export const encrypt = (text: string): string => {
  const reduxCryptoKey = import.meta.env.VITE_REDUX_CRYPTO
  return crypto.AES.encrypt(text, reduxCryptoKey).toString()
}

export const decrypt = (text: string): string => {
  const reduxCryptoKey = import.meta.env.VITE_REDUX_CRYPTO
  return crypto.AES.decrypt(text, reduxCryptoKey).toString(crypto.enc.Utf8)
}
