import { useDataContext } from '@/context/LiveBetting'
import React, { useEffect, useRef } from 'react'

const TableLiveBetting: React.FC = () => {
  // Usa o contexto para obter os dados de jogadas recentes
  const { recentPlays, startFetching, stopFetching } = useDataContext()
  const tableRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          startFetching()
        } else {
          stopFetching()
        }
      },
      { threshold: 0.1 }
    )

    if (tableRef.current) {
      observer.observe(tableRef.current)
    }

    return () => {
      if (tableRef.current) {
        observer.unobserve(tableRef.current)
      }
    }
  }, [startFetching, stopFetching])

  return (
    <section ref={tableRef} className="overflow-x-auto" style={{ paddingBottom: '5rem' }}>
      <div className="bg-secondaryDarkGrey p-1 rounded-lg overflow-x-auto">
        <div className="border-b-[1px] border-b-white mb-5">
          <h2 className="text-white text-lg font-semibold mb-4 p-2">Apostas Ao Vivo</h2>
        </div>
        <div className="overflow-x-auto w-full">
          <table className=" bg-dark-blue overflow-x-auto w-full">
            <thead className="bg-secondaryDarkGrey overflow-x-auto">
              <tr className='overflow-x-auto'>
                <th className="text-left text-sm font-semibold text-white whitespace-nowrap px-2 md:px-4">Jogo</th>
                <th className="text-left text-sm font-semibold text-white whitespace-nowrap px-2 md:px-4">Usuário</th>
                <th className="text-left text-sm font-semibold text-white whitespace-nowrap px-2 md:px-4">Horário</th>
                <th className="text-left text-sm font-semibold text-white whitespace-nowrap px-2 md:px-4">Valor</th>
                <th className="text-left text-sm font-semibold text-white whitespace-nowrap px-2 md:px-4">Multi</th>
                <th className="text-left text-sm font-semibold text-white whitespace-nowrap px-4 md:px-4">Pagamento</th>
              </tr>
            </thead>
            <tbody>
              {recentPlays.map((item, index) => (
                <tr key={index} className="border-t border-gray-700 hover:bg-accent hover:text-white rounded ">
                  <td className="text-sm text-white py-3 font-light px-2 md:px-4 ">
                    {item.jogo.split(' ').map((word, index) => (
                      <span key={index} className="block">
                        {word}
                      </span>
                    ))}
                  </td>
                  <td className="text-sm text-white py-3 whitespace-nowrap px-2 md:px-4">{item.usuario}</td>
                  <td className="text-sm text-white py-3 font-light whitespace-nowrap px-2 md:px-4">{item.tempo}</td>
                  <td className="text-sm text-white py-3 font-light whitespace-nowrap px-7 md:px-4">{item.valor}</td>
                  <td className="text-sm text-white py-3 font-light whitespace-nowrap px-2 md:px-4">{item.multi}</td>
                  <td className="text-sm text-white py-3 font-light whitespace-nowrap px-8 md:px-4">{item.pagamento}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </section>
  )
}

export default TableLiveBetting
