import { resetAppData } from '@/store/app/reducer'
import { AppState } from '@/store/app/state'
import { RootState } from '@/store/store'
import React, { useState, useMemo, ReactNode, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

interface Props {
  children?: ReactNode
}

type DataLinksTypes = {
  id: number
  title: string
  path: string
}

const PolicyPage: React.FC<Props> = ({ children }): JSX.Element => {
  const navigate = useNavigate()
  const location = useLocation()
  const path = location.pathname
  const dispatch = useDispatch()
  const appData: AppState = useSelector((state: RootState) => state.app)

  useEffect(() => {
    if (appData.modalSignIn || appData.modalSignUp) {
      dispatch(resetAppData())
    }
  }, [appData, dispatch])

  const memoComponents = useMemo(
    () => [
      {
        id: 1,
        title: 'Termos de serviço',
        path: '/policy/service',
      },
      {
        id: 2,
        title: 'Politíca de privacidade',
        path: '/policy/privacy',
      },
      {
        id: 3,
        title: 'Jogo Responsavél',
        path: '/policy/responsible',
      },
      {
        id: 4,
        title: 'Politíca de Autoexclusão',
        path: '/policy/autoremove',
      },
      {
        id: 5,
        title: 'Termos de Bonus',
        path: '/policy/bonus',
      },
      {
        id: 6,
        title: 'Torne-se Premium',
        path: '/policy/premium',
      },
    ],
    []
  )
  const [linkSelected, setLinkSelected] = useState<number>(1)

  useEffect(() => {
    const selectElement = memoComponents.find((item: DataLinksTypes) => item.path === path)
    setLinkSelected(selectElement ? selectElement.id : 1)
  }, [memoComponents, path])

  const handleClickLinkSelected = (data: DataLinksTypes): void => {
    setLinkSelected(data.id)
    navigate(data.path)
  }

  return (
    <main className="mt-5 pt-[64px] min-h-screen ">
      <section className="defaultWidth flex flex-col gap-10 lg:gap-10 px-3   pb-[6rem]  overflow-y-auto lg:flex-row">
        <div className="hidden lg:flex flex-col gap-2 h-fit w-full lg:w-[300px] bg-[#484c54] py-4 rounded-xl border-[1px]  border-blue-300/30">
          {memoComponents.map((link) => (
            <div
              key={link.id}
              onClick={() => handleClickLinkSelected(link)}
              className={`flex items-center gap-2 cursor-pointer hover:opacity-90 transition-all ${
                linkSelected === link.id ? 'bg-gradient-to-b from-[#1442c8] to-[#14b1f2]' : ''
              }`}
            >
              <div className={`h-full w-full py-1 px-5 ml-1 ${linkSelected === link.id ? 'bg-[#0f1923]' : ''}`}>
                <span className="text-sm">{link.title}</span>
              </div>
            </div>
          ))}
        </div>
        <div className="w-full">{children}</div>
      </section>
    </main>
  )
}

export default PolicyPage
