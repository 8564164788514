export type TPixKey = 'cpf' | 'cnpj' | 'email' | 'telefone' | 'aleatoria'

export const pixKeyType: { label: string; value: TPixKey }[] = [
  { label: 'CPF', value: 'cpf' },
  // { label: 'CNPJ', value: 'cnpj' },
  // { label: 'Email', value: 'email' },
  // { label: 'Telefone', value: 'telefone' },
  // { label: 'Aleatória', value: 'aleatoria' },
]

export type TWithdraw = {
  userDocument?: string
  amount: any
  pixKey?: string
  typePixkey?: TPixKey
}
