import { ITranslateDictionary } from './interface'

const dictionaryEnglish: Partial<ITranslateDictionary> = {
  customHeader_signin: {
    id: 'customHeader_signin',
    defaultMessage: 'SIGN IN',
  },
  customHeader_signout: {
    id: 'customHeader_signout',
    defaultMessage: 'SIGN OUT',
  },
  customHeader_signup: {
    id: 'customHeader_signup',
    defaultMessage: 'SIGN UP',
  },
}

export default dictionaryEnglish
