import React from 'react'
type Props = {
  className?: string
}
const IconEmail: React.FC<Props> = ({ className }): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 64"
      fill="currentColor"
      className={`w-5 h-5 text-green-01 ${className}`}
    >
      <g id="g19">
        <g id="g25">
          <path d="m32,39.96c-1.34,0-2.62-.42-3.69-1.22L5.59,21.84v-5.11c0-3.41,2.78-6.19,6.19-6.19h40.44c3.41,0,6.19,2.78,6.19,6.19v5.11l-22.72,16.9c-1.08.8-2.35,1.22-3.69,1.22ZM9.71,19.76l21.05,15.66c.74.55,1.73.55,2.46,0l21.05-15.66v-3.03c0-1.14-.93-2.06-2.06-2.06H11.78c-1.14,0-2.06.93-2.06,2.06v3.03Z" />
        </g>
        <g id="g29">
          <path
            id="path31"
            d="m54.29,30.05v17.22c0,1.14-.93,2.06-2.06,2.06H11.78c-1.14,0-2.06-.93-2.06-2.06v-17.22l-4.13-3.07v20.29c0,3.41,2.78,6.19,6.19,6.19h40.44c3.41,0,6.19-2.78,6.19-6.19v-20.29l-4.13,3.07Z"
          />
        </g>
      </g>
    </svg>
  )
}

export default IconEmail
