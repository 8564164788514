import React from 'react'

const Gift: React.FC = (): JSX.Element => {
  return (
    <svg viewBox="0 0 64 64" width="2em" height="2em">
      <g>
        <path
          fill="currentColor"
          d="m28.97,34.99H11.41c-1.39,0-2.53,1.14-2.53,2.53v18.99c0,1.39,1.14,2.53,2.53,2.53h17.86c.6,0,.6-.64.6-.64v-22.55s0-.86-.89-.86Z"
        />
        <path
          fill="currentColor"
          d="m52.59,34.99h-17.54c-1.07,0-.91,1.11-.91,1.11v22.32s0,.62.63.62h17.82c1.39,0,2.53-1.14,2.53-2.53v-18.99c0-1.39-1.14-2.53-2.53-2.53Z"
        />
      </g>
      <g>
        <path
          fill="currentColor"
          d="m29.86,20.05s0-.86-.85-.86H8.55c-1.39,0-2.53,1.14-2.53,2.53v7.9c0,1.39,1.14,2.53,2.53,2.53h20.53c.78,0,.78-.67.78-.67v-11.43h0Z"
        />
        <path
          fill="currentColor"
          d="m55.45,19.19h-20.53c-.78,0-.78.74-.78.74v11.55s0,.66.95.66h20.36c1.39,0,2.53-1.14,2.53-2.53v-7.9c0-1.39-1.14-2.53-2.53-2.53Z"
        />
      </g>
      <path
        fill="currentColor"
        d="m21.26,17.32c-1.16,0-2.21-.09-3.14-.27-2.36-.46-3.97-1.36-4.95-2.74-.87-1.24-1.14-2.76-.79-4.54.61-3.1,2.69-4.81,5.86-4.81.67,0,1.41.08,2.19.23,1.99.39,4.54,1.53,6.82,3.06,3.86,2.59,4.06,4.2,3.86,5.18-.28,1.45-1.64,2.48-4.14,3.16-1.69.46-3.77.72-5.72.72Zm-3.02-8.3c-1.23,0-1.65.34-1.88,1.53-.19.97.05,1.31.13,1.42.33.47,1.19.86,2.41,1.09.66.13,1.48.2,2.36.2,1.95,0,3.66-.29,4.8-.62.08-.02.21-.12.07-.2-1.49-1.21-4.21-2.83-6.47-3.27-.53-.1-1-.16-1.42-.16Z"
      />
      <path
        fill="currentColor"
        d="m42.8,17.32h0c-1.95,0-4.03-.26-5.72-.72-2.5-.68-3.85-1.72-4.14-3.16-.19-.99,0-2.59,3.86-5.18,2.28-1.53,4.83-2.67,6.82-3.06.78-.15,1.52-.23,2.19-.23,3.18,0,5.26,1.71,5.86,4.81.35,1.77.08,3.3-.79,4.54-.97,1.38-2.59,2.28-4.95,2.74-.93.18-1.98.27-3.14.27Zm-4.84-4.9c-.13.08-.07.2,0,.22,1.14.33,2.87.63,4.84.63.88,0,1.7-.07,2.36-.2,1.23-.24,2.08-.63,2.41-1.09.08-.11.32-.45.13-1.42-.23-1.19-.65-1.53-1.88-1.53-.41,0-.89.05-1.42.16-2.27.44-4.95,2.03-6.44,3.25Z"
      />
    </svg>
  )
}

export default Gift
