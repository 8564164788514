import React from 'react';
import ContainerGames from '@/components/molecules/ContainerGames';
import { useGameContext } from '@/context/GameContext';

const Games: React.FC = (): JSX.Element => {
  const { gameCards, newGameCards } = useGameContext();

  return (
      <ContainerGames data={gameCards} newData={newGameCards} />
  );
};

export default Games;
