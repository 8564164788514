import React from 'react'

const PartnerIcon: React.FC = (): JSX.Element => {
  return (
    <svg
      fill="currentColor"
      id="Camada_1"
      data-name="Camada 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 64"
      width="1em"
      height="1em"
    >
      <path d="m38.84,24.87l-6.83,6.83c-2.51,2.51-6.58,2.51-9.09,0-2.51-2.51-2.51-6.59,0-9.09l6.78-6.78c-2.37-1.92-5.25-2.88-8.14-2.87H4.88v15.95h4.06c.53,2.26,1.68,4.41,3.45,6.17.81.81,14.35,14.35,15,15,1.27,1.27,3.38,1.34,4.66.01,1.23-1.27,1.22-3.29-.03-4.55,2.77,2.77,2.17,2.16,2.25,2.25,1.25,1.24,3.27,1.31,4.55.1,1.32-1.25,1.36-3.35.07-4.65,2.84,2.84,2.14,2.14,2.25,2.25,1.28,1.26,3.37,1.32,4.65,0,1.23-1.27,1.22-3.29-.03-4.55,3.05,3.05,2.04,2.04,2.25,2.25,1.25,1.24,3.28,1.31,4.55.1,1.33-1.26,1.35-3.36.07-4.65l-13.8-13.8Z" />
      <path d="m42.45,12.95c-3.32,0-6.63,1.27-9.16,3.8l-8.11,8.11c-1.27,1.27-1.27,3.32,0,4.58,1.27,1.27,3.32,1.27,4.58,0,.87-.87,8.14-8.14,9.08-9.08l13.7,13.7c1.25-1.54,2.09-3.31,2.53-5.15h4.06v-15.95h-16.67,0Z" />
    </svg>
  )
}

export default PartnerIcon
