import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { SubmitHandler, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import subYears from 'date-fns/subYears'
import * as Yup from 'yup'
import ReCAPTCHA from 'react-google-recaptcha-enterprise'

import FormButton from '@/components/atoms/FormButton'
import FormInput from '@/components/atoms/FormInput'
import FormSelect from '@/components/atoms/FormSelect'
import IconEmail from '@/components/atoms/Icons/Email'
import IconPadlock from '@/components/atoms/Icons/Padlock'
import IconUser from '@/components/atoms/Icons/UserLarge'
import Modal from '@/components/atoms/Modal'
import useApiService from '@/service/api.hook'
import useTranslate from '@/service/i18n.hook'
import { setAppData } from '@/store/app/actions'
import { RootState } from '@/store/store'
import { ButtonType } from '@/interfaces/application/buttonType'

export type FormSignup = {
  login: string
  email: string
  password: string
  userTerm: boolean
  invite?: string
  policyTerm: boolean
  cpf: string
  day: number
  month: number
  year: number
}

export type SignupPayload = FormSignup & {
  dateBirth: Date
}

const ModalSignup: React.FC = (): JSX.Element => {
  const isDevelopment = true //import.meta.env.VITE_MODE === 'development'
  const recaptchKey = import.meta.env.VITE_RECAPTCHA_SITE_KEY
  const recaptchaRef = useRef<ReCAPTCHA>(null)
  const [isVerified, setIsverified] = useState<boolean>(isDevelopment)
  const [cpfError, setCpfError] = useState<string | null>(null)
  const dispatch = useDispatch()
  const { registerUser, verifyCaptcha, signIn } = useApiService()
  const { translateMessage, messages } = useTranslate()
  const openModal = useSelector((state: RootState) => state.app.modalSignUp)
  const inviteCode = localStorage.getItem('inviteCode') || ''

  const closeModalSignUp = () => {
    dispatch(setAppData({ modalSignUp: false }))
  }

  const openSigin = () => {
    dispatch(setAppData({ modalSignUp: false, modalSignIn: true, modalRecoveryPassword: false }))
  }

  const handleCaptchaSubmission = async (token: string | null): Promise<void> => {
    try {
      const statusCaptcha = await verifyCaptcha(token)

      if (!statusCaptcha.error) {
        setIsverified(true)
      } else {
        setIsverified(false)
      }
    } catch (error) {
      setIsverified(false)
    }
  }

  const handleFormSubmit: SubmitHandler<FormSignup> = async (data: FormSignup): Promise<void> => {
    // Remove traços e pontos do CPF
    const cpf = data.cpf.replace(/\D/g, '')

    // Bloqueia o envio se o CPF for inválido
    if (!validateCpf(cpf)) {
      setCpfError('CPF Inválido')
      return
    }

    // Merge day, month, and year into a single date
    const dateBirth = new Date(data.year, data.month - 1, data.day)
    const signupData: SignupPayload = { ...data, dateBirth, cpf }

    // Log payload for debugging
    console.log('Payload being sent:', signupData)

    await registerUser(signupData) // Envie signupData com todos os campos necessários
      .then(async (result) => {
        if (!result.error) {
          toast.success('Cadastro realizado com sucesso!')
          await signIn(data.login, data.password)
            .then((result) => {
              if (!result.error) {
                closeModalSignUp()
                toast.success(`Mae.bet lhe da as boas vindas!`)
              } else {
                toast.error(`Erro ao realizar login: ${result.errorObj.response.data.message}`)
              }
            })
            .catch((err) => {
              console.error(err)
              toast.error(`Erro ao realizar login: ${err.response.data.message}`)
            })
        } else {
          toast.error(`Erro ao realizar o cadastro: ${result.errorObj.response.data.message}`)
        }
      })
      .catch((err) => {
        console.error(err)
        toast.error(`Erro ao realizar o cadastro: ${err.response.data.message}`)
      })
  }

  const schema = Yup.object().shape({
    email: Yup.string().email('E-mail inválido').required('Campo obrigatório'),
    login: Yup.string()
      .required('Campo obrigatório')
      .min(5, 'O login deve ter pelo menos 5 caracteres')
      .matches(/^[0-9A-Za-z]*$/, 'Pode conter somente letras sem acento e números'),
    password: Yup.string()
      .required('Campo obrigatório')
      .min(8, 'A senha deve conter pelo menos 8 caracteres')
      .matches(/(?:[0-9].*){1,}/, 'Deve conter pelo menos 1 número')
      .matches(/(?:[A-Z].*){1,}/, 'Deve conter pelo menos 1 letra maiúscula'),
    invite: Yup.string(),
    day: Yup.number()
      .typeError('Dia inválido')
      .required('Campo obrigatório')
      .min(1, 'Dia inválido')
      .max(31, 'Dia inválido'),
    month: Yup.number()
      .typeError('Mês inválido')
      .required('Campo obrigatório')
      .min(1, 'Mês inválido')
      .max(12, 'Mês inválido'),
    year: Yup.number()
      .typeError('Ano inválido')
      .required('Campo obrigatório')
      .min(subYears(new Date(), 100).getFullYear(), 'Ano inválido')
      .max(subYears(new Date(), 18).getFullYear(), 'Você precisa ser maior de 18 anos para se cadastrar'),
    cpf: Yup.string()
      .required('Campo obrigatório')
      .test('is-valid-cpf', 'CPF Inválido', (value) => validateCpf(value || '')),
    policyTerm: Yup.boolean().required('Campo obrigatório'),
    userTerm: Yup.boolean().required('Campo obrigatório'),
  })

  const validateCpf = (cpf: string) => {
    cpf = cpf.replace(/[^\d]+/g, '')
    if (cpf.length !== 11 || /^(\d)\1+$/.test(cpf)) return false
    let sum = 0
    let remainder
    for (let i = 1; i <= 9; i++) sum += parseInt(cpf.substring(i - 1, i)) * (11 - i)
    remainder = (sum * 10) % 11
    if (remainder === 10 || remainder === 11) remainder = 0
    if (remainder !== parseInt(cpf.substring(9, 10))) return false
    sum = 0
    for (let i = 1; i <= 10; i++) sum += parseInt(cpf.substring(i - 1, i)) * (12 - i)
    remainder = (sum * 10) % 11
    if (remainder === 10 || remainder === 11) remainder = 0
    if (remainder !== parseInt(cpf.substring(10, 11))) return false
    return true
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm<FormSignup>({
    resolver: yupResolver(schema),
  })

  useEffect(() => {
    if (inviteCode) {
      setValue('invite', inviteCode)
    }
  }, [inviteCode, setValue])

  return (
    <Modal isOpen={openModal} size="extra-small" closeModal={() => closeModalSignUp()}>
      <div className="px-9 max-h-screen overflow-y-auto">
        <p className="text-center uppercase text-white font-medium">{translateMessage(messages.modalSignup_title)}</p>
        <div className="flex justify-center items-center gap-1 mb-5">
          <span className="font-thin text-xs text-center text-gray-400">
            {translateMessage(messages.modalSignup_subtitle)}
          </span>
          <span className="underline font-bold cursor-pointer text-white" onClick={openSigin}>
            {translateMessage(messages.modalSignup_enter)}
          </span>
        </div>
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <FormInput
            className="mb-3"
            register={register}
            errors={errors}
            control={control}
            required
            icon={<IconEmail />}
            type="email"
            name="email"
            placeholder="Insira o seu e-mail"
            autoComplete="email"
            label="E-mail"
          />

          <FormInput
            className="mb-3"
            register={register}
            errors={errors}
            control={control}
            required
            icon={<IconUser />}
            type="text"
            name="login"
            placeholder="Insira o seu nome de usuário"
            autoComplete="username"
            label="Usuário"
          />

          <FormInput
            className="mb-3"
            register={register}
            errors={errors}
            control={control}
            required
            type="password"
            name="password"
            placeholder="Digite uma senha"
            autoComplete="new-password"
            label="Senha"
            icon={<IconPadlock />}
          />

          <FormInput
            className="mb-3"
            register={register}
            errors={errors}
            control={control}
            formatType="cpf"
            name="cpf"
            placeholder="Insira o seu CPF"
            label="CPF"
            required
            maxLength={14}
            onChange={() => setCpfError(null)}
          />

          <FormInput
            className="mb-3"
            register={register}
            errors={errors}
            control={control}
            type="text"
            formatType="text-upper"
            name="invite"
            value={inviteCode ? inviteCode : undefined}
            placeholder="Digite um código de convite (opcional)"
            label="Código de convite (opcional)"
          />

          <div className="mb-3">
            <label className="block text-white mb-2">Data de nascimento</label>
            <div className="flex gap-2">
              <FormInput
                className="mb-0"
                register={register}
                errors={errors}
                control={control}
                type="number"
                name="day"
                placeholder="DD"
                autoComplete="bday-day"
                maxLength={2}
              />
              <FormSelect
                register={register}
                errors={errors}
                name="month"
                placeholder="MM"
                options={[...Array(12)].map((_, i) => ({
                  value: i + 1,
                  label: i + 1,
                }))}
              />
              <FormSelect
                register={register}
                errors={errors}
                name="year"
                placeholder="AAAA"
                options={[...Array(100)].map((_, i) => {
                  const year = new Date().getFullYear() - i
                  return { value: year, label: year }
                })}
              />
            </div>
          </div>

          {!isDevelopment && (
            <div className="mb-5 flex items-center justify-center">
              <ReCAPTCHA sitekey={recaptchKey} ref={recaptchaRef} onChange={handleCaptchaSubmission} />
            </div>
          )}

          <FormInput
            className="mb-5 mt-10"
            register={register}
            errors={errors}
            control={control}
            required
            type="checkbox"
            name="userTerm"
            placeholder=""
            label={
              <>
                Eu concordo com os{' '}
                <Link to="/policy/service" target="_blank" className="cursor-pointer">
                  Termos de Uso do Site
                </Link>{' '}
              </>
            }
          />

          <FormInput
            className="mb-10"
            register={register}
            errors={errors}
            control={control}
            required
            type="checkbox"
            name="policyTerm"
            placeholder=""
            label={
              <>
                Eu concordo com os{' '}
                <Link to="/policy/privacy" target="_blank" className="cursor-pointer">
                  Política de Privacidade e Proteção de Dados
                </Link>
              </>
            }
          />

          <div className="mb-5">
            <FormButton
              title="Cadastrar"
              type="submit"
              className="uppercase font-medium rounded-md"
              buttonType={
                !isVerified || errors.policyTerm || errors.userTerm || cpfError ? ButtonType.Disabled : ButtonType.Confirm
              }
            ></FormButton>
          </div>
        </form>
      </div>
    </Modal>
  )
}

export default ModalSignup
