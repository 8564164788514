import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppState } from './state'

const initialState: AppState = {
  locale: 'pt-BR',
  loading: false,
  modalSignUp: false,
  modalSignIn: false,
  modalRecoveryPassword: false,
  modalPix: { status: false },
  modalWidthdraw: false,
  modalBanner: { status: false, type: '' },
}

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setAppData: (state: AppState, action: PayloadAction<Partial<AppState>>) => {
      state.locale = action.payload.locale ?? state.locale
      state.modalSignUp = action.payload.modalSignUp ?? state.modalSignUp
      state.modalSignIn = action.payload.modalSignIn ?? state.modalSignIn
      state.modalRecoveryPassword = action.payload.modalRecoveryPassword ?? state.modalRecoveryPassword
      state.loading = action.payload.loading ?? state.loading
      state.modalPix = action.payload.modalPix ?? state.modalPix
      state.modalWidthdraw = action.payload.modalWidthdraw ?? state.modalWidthdraw
      state.modalBanner = action.payload.modalBanner ?? state.modalBanner

    },
    resetAppData: (state: AppState) => {
      state.locale = 'pt-BR'
      state.loading = false
      state.modalSignUp = false
      state.modalSignIn = false
      state.modalRecoveryPassword = false
      state.modalPix = { status: false }
      state.modalWidthdraw = false
      state.modalBanner = { status: false, type: '' }
    },
    preserveAppData: (state: AppState) => {
      state.locale = 'pt-BR'
      state.loading = false
      state.modalSignUp = false
      state.modalRecoveryPassword = false
      state.modalPix = { status: false }
      state.modalWidthdraw = false
      state.modalBanner = { status: false, type: '' }
    },
  },
})

export const { setAppData, resetAppData, preserveAppData } = appSlice.actions
export const appReducer = appSlice.reducer
