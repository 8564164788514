// icon:telegram-alt | Unicons https://iconscout.com/unicons | Iconscout
import * as React from 'react'

type Props = {
  height?: number
  width?: number
}

const IconTelegramAlt: React.FC<Props> = ({ height, width }) => {
  return (
    <svg data-name="Layer 1" viewBox="0 0 24 24" fill="currentColor" width={width || 18} height={height || 18}>
      <path d="M17.688 21.744a2.02 2.02 0 01-1.242-.427l-4.03-3.122-2.702 2.983a1 1 0 01-1.698-.383l-2.02-6.682-3.626-1.26a2.042 2.042 0 01-.103-3.818L20.187 1.8a2.042 2.042 0 012.771 2.295L19.695 20.11a2.054 2.054 0 01-2.008 1.633z" />
      <path d="M8.973 21.506a1 1 0 01-.957-.71l-2.168-7.16a.999.999 0 01.495-1.176L16.91 6.958a1 1 0 011.17 1.594l-7.084 7.083-1.044 5.072a1 1 0 01-.933.798h-.046z" />
    </svg>
  )
}

export default IconTelegramAlt
