import React from 'react'

type Props = {
  height?: number
  width?: number
}

const IconKwai: React.FC<Props> = ({ height, width }) => {
  return (
    <svg
      id="Camada_1"
      data-name="Camada 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 64"
      width={width || 18}
      height={height || 18}
      fill="currentColor"
    >
      <path d="m21.19,54.29c-.77,0-2.59-.38-4.42-.86s-3.07-1.15-3.07-1.15c-.67-.38-.96-.96-1.06-1.54,0,0-.19-1.92-.19-5.57,0-2.79.1-4.32.19-4.71.1-.48.29-.86.96-.86s1.44,0,3.46.38c2.4.48,3.55.86,4.13,1.06.48.19,1.06.58,1.15,1.34,0,0,.29,2.02.29,5.09,0,2.5-.19,5-.29,5.76-.1.67-.38,1.06-1.15,1.06h0Zm10.86-8.65c0-.48-.19-.86-.48-1.15l-6.24-6.15c-.67-.77-1.54-1.25-2.59-1.44,0,0-2.31-.58-5.86-1.15-3.46-.58-4.42-.48-4.42-.48-3.07,0-3.17,3.27-3.27,3.65-.1.77-.29,2.88-.29,5.86,0,3.46.19,5.86.29,7.4.29,2.21,2.02,3.27,2.79,3.75.19.1,2.4,1.06,4.52,1.73,1.44.48,3.84,1.15,4.9,1.25.38.1,2.5.38,3.55-.96l6.63-8.55c.29-.38.38-.58.38-1.15l.1-2.59h0Zm14.51-12.49c-.96-.1-1.92-.1-2.79-.1l-14.89,1.44s-.96.19-1.54.86c-.48.58-.48,1.06-.48,1.06l.38.38,5.86,5.96c.67.67,1.06,1.25,1.06,2.21v3.36c0,.58-.1,1.15-.38,1.54l-3.17,4.32,3.17,1.25c1.25.38,2.31.38,3.55.19l13.64-3.17s2.5-.67,2.5-2.98v-8.36c0-.19-.1-.29-.29-.29l-8.74,1.73c-.29.1-.58.38-.58.67v4.03c0,.29.29.48.58.38l2.69-.58c.1,0,.29.1.29.29v1.34c0,.29-.19.48-.48.48l-3.07.67s-2.11.58-2.11-1.73v-5.19c0-2.21,1.63-2.5,1.63-2.5l9.61-1.83c.29,0,.48-.29.48-.48v-2.11c0-2.31-2.21-2.5-2.21-2.5l-4.71-.38h0ZM28.69,11.35c3.55.19,6.24,3.55,6.05,7.69s-3.27,7.4-6.72,7.3c-3.55-.19-6.15-3.55-5.96-7.69.19-4.23,3.17-7.49,6.63-7.3Zm14.41,9.61c.19-2.79,2.11-5,4.52-4.9,2.21.1,4.03,2.5,3.94,5.19-.19,2.79-2.21,5-4.52,4.9-2.31-.1-4.03-2.4-3.94-5.19Zm12.01.38c0-5.57-4.13-9.99-9.22-9.99-2.98,0-5.67,1.54-7.4,3.94-1.34-5.96-6.24-10.28-12.2-10.28-7.11,0-12.78,6.15-12.68,13.64,0,7.59,5.76,13.83,12.68,13.74.38,0,19.69-1.34,19.6-1.34,5.48-.48,9.22-4.23,9.22-9.7h0Z" />
    </svg>
  )
}

export default IconKwai
