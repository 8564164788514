import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AuthState } from './state'

const initialState: AuthState = {
  accessToken: '',
  expirationTime: 0,
  authenticated: false,
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthData: (state: AuthState, action: PayloadAction<Partial<AuthState>>) => {
      state.accessToken = action.payload.accessToken ?? state.accessToken
      state.expirationTime = action.payload.expirationTime ?? state.expirationTime
      state.authenticated = !!action.payload.accessToken
    },
    resetAuthData: (state: AuthState) => {
      state.accessToken = ''
      state.expirationTime = 0
      state.authenticated = false
    },
  },
})

export const { setAuthData, resetAuthData } = authSlice.actions
export const authReducer = authSlice.reducer
