import React from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import simpleLogo from '@/assets/logos/logo-maebet.png'
import IconIconExternalLink from '@/components/atoms/Icons/IconIconExternalLink'
import IconInstagram from '@/components/atoms/Icons/IconInstagram'
import IconTelegramAlt from '@/components/atoms/Icons/IconTelegramAlt'
import IconTiktok from '@/components/atoms/Icons/IconTiktok'
import IconWhatsappFill from '@/components/atoms/Icons/IconWhatsappFill'
import { setAppData } from '@/store/app/reducer'
// import { RootState } from '@/store/store'

// Importando as novas imagens
import aware from '@/assets/footer/aware.png'
import master from '@/assets/footer/master.png'
import pix from '@/assets/footer/pix.png'
import visa from '@/assets/footer/visa.png'
import icon18 from '@/assets/footer/+18.png'

interface BaseLink {
  name: string
  href?: string
}

interface IconLink extends BaseLink {
  icon: React.ReactElement
}

interface FunctionLink extends BaseLink {
  function: () => void
}

const Footer: React.FC = (): JSX.Element => {
  const currentYear = new Date().getFullYear()
  const dispatch = useDispatch()
  //const authenticatedRedux = useSelector((state: RootState) => state.auth.authenticated)

  const languageSwitch = (newlocale: 'pt-BR' | 'es' | 'en-US') => {
    dispatch(setAppData({ locale: newlocale }))
  }

  //   Abre modal de bonus se logado senão abre login
  // const bonus = () => {
    // TODO aguardando a criação do modal
 //   if (authenticatedRedux) {
//      dispatch(setAppData({ modalBanner: { status: true, type: 'bonus' } }))
 //   } else {
 //     dispatch(setAppData({ modalSignIn: true }))
//    }
//  }

  const socialLinks: IconLink[] = [
    { name: 'Whatsapp', icon: <IconWhatsappFill />, href: 'https://whatsapp.com/channel/0029VaeIgG3DzgT7CIxbiP1k' },
    { name: 'Instagram', icon: <IconInstagram />, href: 'https://www.instagram.com/Mae.bet' },
    { name: 'Telegram', icon: <IconTelegramAlt />, href: 'https://t.me/bcashbet' },
    { name: 'Tiktok', icon: <IconTiktok />, href: '/' },
  ]

  const supportLinks: BaseLink[] = [
    { name: 'Whatsapp', href: 'https://whatsapp.com/channel/0029VaeIgG3DzgT7CIxbiP1k' },
    { name: 'Instagram', href: 'https://www.instagram.com/Mae.bet' },
    { name: 'Telegram', href: 'https://t.me/bcashbet' },
    { name: 'Tiktok', href: '/' },
  ]

  const languageLinks: FunctionLink[] = [
    { name: 'Português', function: () => languageSwitch('pt-BR') },
    // { name: 'English', function: () => languageSwitch('en-US') },
    // { name: 'Español', function: () => languageSwitch('es') },
  ]

  // const casinoLinks: FunctionLink[] = [
    // { name: 'Bônus de 1000%', function: () => bonus() },
    // { name: 'Torne se Premium', function: () => premium() },
    // { name: 'Ativar ganhos em dobro', function: () => doubleGain() },
 // ]

  const gameLinks: BaseLink[] = [
    { name: 'Kung-fu Fortune', href: '/gaming/kung-fu-fortune' },
    { name: 'Bolsonaro Fortune', href: '/gaming/bolsonaro-fortune' },
    { name: 'Lula Fortune', href: '/gaming/lula-fortune' },
  ]

  const aboutLinks: BaseLink[] = [
    { name: 'Politica de privacidade', href: '/policy/privacy' },
    { name: 'Termos de serviço', href: '/policy/service' },
    { name: 'Jogo responsável', href: '/policy/responsible' },
    { name: 'Auto exclusão', href: '/policy/autoremove' },
    { name: 'Politica de Bônus', href: '/policy/bonus' },
    // { name: 'Premium', href: '/policy/premium' },
  ]

  return (
    <footer className="bg-primary border-t-[2px] border-blue-500/30 text-white">
    {/* <footer className="bg-gradient-to-l from-blue-950 via-[#091228] to-[#091231] border-t-[2px] border-blue-500/30"> */}
      <section className="defaultWidth py-16 px-6 space-y-10">
        {/* Div superior */}
        <div className="flex flex-col lg:flex-row justify-between gap-8 lg:gap-20">
          {/* Logo e Descrição */}
          <div className="flex flex-col gap-4 w-full lg:w-[30%] px-4">
            <div className="flex gap-2 lg:gap-4">
              <div className="w-18 -my-5 -ml-2 ">
                <Link to={'/'}>
                  <div className="flex items-center gap-1">
                    <img className="w-11 h-9" src={simpleLogo} alt="Erro" height={64} width={64} />
                    {/* Text bcash */}
                    <div className="lg:block font-bold">
                      <span>Mae.bet</span>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
            <div>
              <p className="text-xs opacity-60">
                Mae.bet é o melhor cassino online da América Latina. Jogos rápidos, seguros e transparentes com uma
                experiência única de games exclusivos desenvolvidos pela plataforma.
              </p>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-[15px] lg:grid-cols-5 p-4 lg:p-0 lg:w-[70%]">
            {/* Suporte */}
            <div className="space-y-2 w-full mb-4">
              <h4 className="text-sm">Suporte</h4>
              <div className="space-y-2">
                {supportLinks.map((link, index) => (
                  <div key={index} className="flex items-center gap-1 text-xs opacity-60 hover:opacity-100">
                    <a href={link.href}>{link.name}</a>
                    <IconIconExternalLink />
                  </div>
                ))}
              </div>
            </div>
            {/* Cassino */}
            {/* <div className="space-y-2 w-full mb-4">
              <h4 className="text-sm">Cassino</h4>
              <div className="flex flex-col gap-2">
                {casinoLinks.map((link, index) => (
                  <a key={index} onClick={link.function} className="text-xs opacity-60 hover:opacity-100 cursor-pointer">
                    {link.name}
                  </a>
                ))}
              </div>
            </div> */}
            {/* Jogos */}
            <div className="space-y-2 w-full mb-4">
              <h4 className="text-sm">Jogos</h4>
              <div className="flex flex-col gap-2">
                {gameLinks.map((link, index) => (
                  <a key={index} href={link.href} className="text-xs opacity-60 hover:opacity-100">
                    {link.name}
                  </a>
                ))}
              </div>
            </div>
            {/* Sobre */}
            <div className="space-y-2 w-full mb-4">
              <h4 className="text-sm">Sobre nós</h4>
              <div className="flex flex-col gap-2">
                {aboutLinks.map((link, index) => (
                  <a key={index} href={link.href} className="text-xs opacity-60 hover:opacity-100">
                    {link.name}
                  </a>
                ))}
              </div>
            </div>
            {/* Idioma */}
            <div className="space-y-2 w-full mb-4">
              <h4 className="text-sm">Idioma</h4>
              <div className="flex flex-col gap-2">
                {languageLinks.map((link, index) => (
                  <span key={index} onClick={link.function} className="text-xs opacity-60 hover:opacity-100 cursor-pointer">
                    {link.name}
                  </span>
                ))}
              </div>
            </div>
          </div>
        </div>
        <hr className="opacity-30" />
        <div className="block md:hidden">
          {/* Adicionando a nova seção de imagens */}
          <div className="" style={{ marginTop: '0px !important', marginBottom: '0px !important' }}>
            <div className=" w-full mb-4 flex justify-between">
              <img className="flex-1 max-w-[25%] object-contain" src={pix} alt="Pix" />
              <img className="flex-1 max-w-[25%] object-contain" src={visa} alt="Visa" />
              <img className="flex-1 max-w-[15%] object-contain" src={master} alt="Mastercard" />
            </div>
          </div>
          <div className="l" style={{ marginTop: '0px !important', marginBottom: '0px !important' }}>
            <div className=" w-full mb-4 flex justify-center">
              <img className="flex-1 max-w-[43%] object-contain" src={aware} alt="BeGambleAware" />
              {/* <img className="flex-1 max-w-[43%] object-contain" src={bcashSecurity} alt="bcash Security" /> */}
            </div>
          </div>

          <hr className="opacity-30" />
          <div className="w-full mb-4 flex justify-center mt-5 ">
            <img className="max-w-[19%] object-contain  " src={icon18} alt="BeGambleAware" />
          </div>

          <div className="w-full mb-4 flex justify-center text-xs">
            <p className="text-center">
              <span className="font-bold">O acesso de pessoas menores de 18 anos é estritamente proibido.</span> É
              responsabilidade de cada jogador agir de acordo com os regulamentos em vigor, além dos nossos termos e
              condições. O jogo envolve risco financeiro, jogue com cuidado. Apostar pode ser viciante. Jogue
              responsavelmente!
            </p>
          </div>
          <div className="w-full mb-4 flex flex-col justify-center items-center mb-5 text-xs">
            <p className="text-center">
              <span className="font-bold">Suporte</span> support@Mae.bet
            </p>
            <p className="text-center">
              <span className="font-bold">Parceiros</span> partners@Mae.bet
            </p>
            <p className="text-center">
              <span className="font-bold">Imprensa</span> press@Mae.bet
            </p>
          </div>
          <hr className="opacity-30" />
        </div>
        {/* Div inferior */}
        <div className="flex flex-col lg:flex-row justify-center items-center gap-6 lg:justify-between">
          {/* Copyright */}
          <div className="text-xs order-2 lg:order-1">&copy; {currentYear} Mae.bet | Todos os Direitos Reservados</div>
          {/* Redes Sociais */}
          <div className="flex gap-2 order-1 md-order-2 ">
            {socialLinks.map((item, index) => (
              <div key={index} className="p-2 rounded-full border border-white/20 bg-accent ">
                <a href={item.href} className="text-xs opacity-60 hover:opacity-100">
                  {item.icon}
                </a>
              </div>
            ))}
          </div>
        </div>
      </section>
    </footer>
  )
}

export default Footer
