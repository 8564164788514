import React, { useEffect, useState } from 'react'
import IconClose from '../Icons/Close'
import closeIcon from '@/assets/icones/close-desktop.png'

interface Props {
  isOpen: boolean
  className?: string
  children: React.ReactElement
  closeModal: () => void
  size: 'custom' | 'micro' | 'tiny' | 'extra-small' | 'small' | 'medium' | 'large'
  border?: 'blue' | 'gold'
  nopadding?: boolean
  fullScreenWithMargin?: boolean
  usePosition?: boolean
  positionStyle?: React.CSSProperties // Novo prop para estilo de posição
}

const Modal: React.FC<Props> = ({
  isOpen,
  className,
  children,
  closeModal,
  size,
  border,
  nopadding,
  fullScreenWithMargin = false,
  usePosition = false,
  positionStyle,
}): JSX.Element => {
  const [classSize, setClassSize] = useState('')
  const [classBorder, setClassBorder] = useState('border-accent')

  useEffect(() => {
    switch (size) {
      case 'custom':
        setClassSize('w-[390px] h-fit')
        break
      case 'micro':
        setClassSize('lg:w-1/6 lg:h-fit')
        break
      case 'tiny':
        setClassSize('lg:w-1/4 lg:h-fit')
        break
      case 'extra-small':
        setClassSize('w-full h-full lg:w-[420px] lg:h-fit')
        break
      case 'small':
        setClassSize('lg:w-3/4 lg:h-4/5')
        break
      case 'medium':
        setClassSize('lg:w-3/4 lg:h-9/10')
        break
      case 'large':
        setClassSize('lg:w-3/4 lg:h-full')
        break
      default:
        break
    }
  }, [size])

  useEffect(() => {
    switch (border) {
      case 'blue':
        setClassBorder('border-pallet7')
        break
      case 'gold':
        setClassBorder('border-pallet5')
        break
    }
  }, [border])

  // Bloquear rolagem da tela quando o modal está aberto
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden' // Bloquear rolagem
    } else {
      document.body.style.overflow = ''
    }

    return () => {
      document.body.style.overflow = ''
    }
  }, [isOpen])

  if (!isOpen) {
    return <></>
  }

  return (
    <div
      id="modal"
      className={`fixed top-0 left-0 w-full h-full flex items-start justify-center ${className ? className : ''} ${
        !(fullScreenWithMargin || usePosition) ? 'backdrop-blur-sm' : ''
      }`}
      style={{
        zIndex: 2000,
        pointerEvents: fullScreenWithMargin || usePosition ? 'none' : 'auto',
      }}
    >
      <div
        id="content"
        className={`relative ${fullScreenWithMargin ? '' : classBorder} ${
          fullScreenWithMargin ? '' : 'border'
        } overflow-auto ${
          fullScreenWithMargin ? 'rounded-none bg-[#0f1923]' : 'rounded-lg bg-[#0f1923]'
        } py-3 ${classSize} ${nopadding ? '' : 'px-3'} ${
          fullScreenWithMargin ? 'w-full h-[calc(100%-12rem)]' : 'h-full lg:h-auto'
        }`}
        style={{
          ...(usePosition ? positionStyle : { margin: 'auto' }), // Conditionally apply styles
          ...(fullScreenWithMargin ? { marginTop: '12rem' } : {}), // Add marginTop if fullScreenWithMargin is true
          pointerEvents: 'auto',
        }}
      >
        <div onClick={() => closeModal()} className="w-[25px] cursor-pointer z-20 absolute r-0 mr-2" style={{ right: 0 }}>
          {!fullScreenWithMargin && (
            <div className="block lg:hidden">
              <img src={closeIcon} alt="Close" />
            </div>
          )}

          {!usePosition && (
            <div className="hidden lg:block">
              <IconClose />
            </div>
          )}
        </div>
        {children}
      </div>
    </div>
  )
}

export default Modal
