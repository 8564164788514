import React, { useEffect, useRef, useState } from 'react'
import { CardGames } from '@/interfaces/application/cardGames'
import useDevice from '@/service/device.hook'
import 'swiper/css'
import 'swiper/css/pagination'
import './styles.css'
import GamesOriginals from '../GamesOriginals'
import Top10Games from '../Top10Games'

type Props = {
  data: CardGames[]
  newData: CardGames[]
}

const ContainerGames: React.FC<Props> = ({ data, newData }): JSX.Element => {
  const [getMaxSlides, setMaxSlides] = useState<number>(7)
  console.log(getMaxSlides)
  const [activeCategory] = useState<string>('exclusivos')
  const inputRef = useRef<HTMLInputElement | null>(null)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const { isMobile } = useDevice()
  const maxSlideDesktop = 5
  const maxSlideMobile = 3

  useEffect(() => {
    const maxSlides = isMobile ? maxSlideMobile : maxSlideDesktop
    setMaxSlides(maxSlides)
  }, [isMobile, maxSlideDesktop, maxSlideMobile])

  const handleClickOutside = (event: MouseEvent) => {
    if (
      inputRef.current &&
      !inputRef.current.contains(event.target as Node) &&
      !document.querySelector('.modal-class')?.contains(event.target as Node)
    ) {
      setIsModalOpen(false)
    }
  }

  useEffect(() => {
    if (isModalOpen) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isModalOpen])

  useEffect(() => {}, [activeCategory, data])


  return (
    <div
      // className="w-full relative flex flex-col justify-center items-start"
      id="container-games"
    >
      <GamesOriginals data={data} />

      <div className="py-2">
        <Top10Games newData={newData} />
      </div>
    </div>
  )
}

export default ContainerGames
