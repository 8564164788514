import React from 'react'


interface Props {
  register: any
  errors: any
  required?: boolean
  readonly?: boolean
  name: string
  className?: string
  label?: string | JSX.Element
  placeholder?: string
  options: { value: string | number; label: string | number }[]
}

const FormSelect: React.FC<Props> = ({
  register,
  errors,
  required,
  readonly,
  name,
  className,
  label,
  placeholder,
  options,
}: Props): JSX.Element => {
  const isError = errors[name]
  

  return (
    <div className={`w-full space-y-1 ${className}`}>
      <label htmlFor={name} className="block text-xs font-thin ml-2 mb-2">
        {label}
        {required && <span className="text-red-500">*</span>}
      </label>
      <div className="relative">
        <select
          id={name}
          {...register(name)}
          className={`text-sm py-2 px-4 focus:outline-none text-white bg-[#0f1923] rounded-md border border-[#344056] w-full ${
            readonly ? 'cursor-not-allowed' : ''
          }`}
          disabled={readonly}
        >
          <option value="" disabled>
            {placeholder}
          </option>
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
      {isError && (
        <div>
          <p className="text-red-500 text-xs mt-1">{errors[name]?.message}</p>
        </div>
      )}
    </div>
  )
}

export default FormSelect
