import FormButton from '@/components/atoms/FormButton'
import IconCopiarLink from '@/components/atoms/Icons/CopiarLink'
import Modal from '@/components/atoms/Modal'
import { ButtonType } from '@/interfaces/application/buttonType'
// import useApiService from '@/service/api.hook'
import { setAppData } from '@/store/app/actions'
import { RootState } from '@/store/store'
import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'

export type FormPix = {
  login: string
  password: string
}

const ModalPix: React.FC = (): JSX.Element => {
//   const maxRetry = 100
  const dispatch = useDispatch()
//   const { depositWait } = useApiService()

  const modalData = useSelector((state: RootState) => state.app.modalPix)

  const closeModalPix = useCallback(() => {
    dispatch(setAppData({ modalPix: { status: false } }))
  }, [dispatch])

//   const pixAwait = useCallback(
//     async (id: string, iteractions: number) => {
//       const pixResponse = await depositWait(id)
//       console.log(iteractions, '<=', maxRetry)
//       if (pixResponse.data && iteractions > 0) {
//         if (pixResponse.data.status !== 'confirmed') {
//           setTimeout(async () => pixAwait(id, iteractions - 1), 6000)
//         } else {
//           toast.success(`Deposito realizado, em instantes seu saldo será atualizado`)
//           closeModalPix()
//         }
//       } else {
//         toast.error(`Infelizmente não recebemos resposta, tente novamente.`)
//         closeModalPix()
//       }
//     },
//     [closeModalPix, depositWait]
//   )

//   useEffect(() => {
//     if (modalData.data?.id) {
//       pixAwait(modalData.data?.id, maxRetry).then((r) => r)
//     }
//   }, [modalData, pixAwait])

  const copyCode = () => {
    navigator.clipboard.writeText(modalData.data?.contentQr || '')
    toast.success(`Copiado com sucesso`)
  }

  return (
    <Modal isOpen={modalData.status} size="extra-small" closeModal={() => closeModalPix()}>
      <div className="px-9">
        <p className="text-center uppercase text-white font-medium">PIX: QR CODE / COPIA E COLA</p>
        <div className="flex justify-center items-center gap-1 mb-5">
          <span className="font-thin text-xs text-center  text-gray-400">
            Quase lá, use o app do seu banco para finalizar o depósito.
          </span>
        </div>
        <div className="mt-4 flex justify-center">
          <img src={'data:image/jpeg;base64, ' + modalData.data?.qrCodeLink} alt="" />
        </div>
        <textarea rows={5} readOnly className="mt-4 w-[100%] bg-secondaryDarkGrey h-[auto] break-words whitespace-pre-line">
          {modalData.data?.contentQr}
        </textarea>
        <FormButton
          title="Copiar código"
          onClick={copyCode}
          type="submit"
          className={'w-[auto] h-[38px] rounded-full mr-4 flex items-center '}
          buttonType={ButtonType.Deposit}
        >
          <div className="mr-1">
            <IconCopiarLink />
          </div>
        </FormButton>
      </div>
    </Modal>
  )
}

export default ModalPix
