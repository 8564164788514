import React from 'react'

const IconUser: React.FC<React.SVGProps<SVGSVGElement>> = (props: React.SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg viewBox="0 0 64 64" fill="currentColor" height="1em" width="1em" {...props}>
      <path d="m51.6,39.58c-3.02-3.02-6.61-5.25-10.54-6.61,4.2-2.89,6.96-7.73,6.96-13.21,0-8.84-7.19-16.03-16.03-16.03S15.97,10.93,15.97,19.77c0,5.47,2.76,10.32,6.96,13.21-3.92,1.35-7.52,3.59-10.54,6.61-5.24,5.24-8.12,12.2-8.12,19.6h4.33c0-12.9,10.49-23.39,23.39-23.39s23.39,10.49,23.39,23.39h4.33c0-7.41-2.88-14.37-8.12-19.6Zm-19.6-8.12c-6.45,0-11.7-5.25-11.7-11.7s5.25-11.7,11.7-11.7,11.7,5.25,11.7,11.7-5.25,11.7-11.7,11.7Z" />
    </svg>
  )
}

export default IconUser
