import React from 'react'

type Props = {
  className: string
}
const IconUploadFile: React.FC<Props> = ({ className }): JSX.Element => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" width={18} height={18} className={className}>
      <defs>
        <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="0%" style={{ stopColor: '#1442c8', stopOpacity: 1 }} />
          <stop offset="100%" style={{ stopColor: '#14b1f2', stopOpacity: 1 }} />
        </linearGradient>
      </defs>
      <path
        fill="url(#gradient)"
        d="m57.07,14.79c-1.47-1.76-3.59-2.86-5.88-3.03l-26.02-3.66c-2.24-.46-4.58,0-6.47,1.29-1.77,1.47-2.86,3.59-3.04,5.88l-.36,2.56h-1.37c-4.17-.48-7.94,2.5-8.42,6.67-.07.58-.07,1.17,0,1.75v21.36c-.48,4.17,2.5,7.94,6.67,8.42.58.07,1.17.07,1.75,0h26.29c4.7,0,7.57-2.25,8.25-6.38,3.84-.25,6.85-3.39,6.94-7.24l2.96-21.14c.47-2.24,0-4.58-1.29-6.48h0Zm-12.13,32.82c0,3.36-1.37,4.72-4.73,4.72H13.93c-3.35,0-4.72-1.37-4.72-4.72v-21.36c0-3.36,1.37-4.72,4.72-4.72h26.29c3.36,0,4.73,1.37,4.73,4.72v21.36Zm9.75-26.86l-2.96,21.14c-.36,2.48-1.29,3.73-3.1,4.04v-19.67c.48-4.17-2.5-7.94-6.67-8.42-.58-.07-1.17-.07-1.76,0h-21.19l.29-2.04c.06-1.31.63-2.55,1.6-3.45,1.12-.67,2.47-.88,3.74-.57l26.02,3.65c1.31.06,2.55.63,3.45,1.59.67,1.12.88,2.47.57,3.74h0Zm-19.61,16.18c0,1.02-.83,1.85-1.85,1.85h-4.31v4.31c0,1.02-.83,1.85-1.85,1.85s-1.85-.83-1.85-1.85v-4.31h-4.31c-1.02,0-1.85-.83-1.85-1.85s.83-1.85,1.85-1.85h4.31v-4.31c0-1.02.83-1.85,1.85-1.85s1.85.83,1.85,1.85v4.31h4.31c1.02,0,1.85.83,1.85,1.85Z"
      />
    </svg>
  )
}

export default IconUploadFile
