import React from 'react'

const IconCopiarLink: React.FC<React.SVGProps<SVGSVGElement>> = (props: React.SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      fill="#fff"
      width="1em"
      height="1em"
      id="Camada_1"
      data-name="Camada 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 64"
      {...props}
    >
      <path d="m33.84,41.22l-7.37,7.37s0,0,0,0c0,0,0,0,0,0-3.05,3.05-8.01,3.05-11.06,0-1.48-1.48-2.29-3.44-2.29-5.53s.81-4.05,2.29-5.53c0,0,0,0,0,0l7.37-7.37c1.02-1.02,1.02-2.67,0-3.69-1.02-1.02-2.67-1.02-3.69,0l-7.37,7.37s0,0,0,0c-2.46,2.46-3.82,5.73-3.82,9.21s1.36,6.75,3.82,9.22c2.54,2.54,5.88,3.81,9.22,3.81s6.68-1.27,9.22-3.81h0s0,0,0,0l7.37-7.37c1.02-1.02,1.02-2.67,0-3.69-1.02-1.02-2.67-1.02-3.69,0Z" />
      <path d="m56.09,20.94c0-3.48-1.36-6.75-3.82-9.22-5.08-5.08-13.35-5.08-18.43,0,0,0,0,0,0,0l-7.37,7.37c-1.02,1.02-1.02,2.67,0,3.69.51.51,1.18.76,1.84.76s1.33-.25,1.84-.76l7.37-7.37s0,0,0,0c3.05-3.05,8.01-3.05,11.06,0,1.48,1.48,2.29,3.44,2.29,5.53s-.81,4.05-2.29,5.53c0,0,0,0,0,0l-7.37,7.37c-1.02,1.02-1.02,2.67,0,3.69.51.51,1.18.76,1.84.76s1.33-.25,1.84-.76l7.37-7.37s0,0,0,0c2.46-2.46,3.82-5.73,3.82-9.21Z" />
      <path d="m22.78,41.22c.51.51,1.18.76,1.84.76s1.33-.25,1.84-.76l14.75-14.75c1.02-1.02,1.02-2.67,0-3.69-1.02-1.02-2.67-1.02-3.69,0l-14.75,14.75c-1.02,1.02-1.02,2.67,0,3.69Z" />
    </svg>
  )
}

export default IconCopiarLink
