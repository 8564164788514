// import * as Sentry from '@sentry/react'
import React from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-tooltip/dist/react-tooltip.css'
import ReactDOM from 'react-dom/client'
import App from './App.tsx'
import './index.css'
import { HeaderProvider } from './components/organisms/HeaderContext'

if (window.location.host.startsWith('www')) {
  location.href =
    window.location.protocol +
    '//' +
    window.location.host.replace('www.', '') +
    window.location.pathname +
    window.location.search
}

// Sentry.init({
//   dsn: import.meta.env.VITE_SENTRY_DSN,
//   environment: import.meta.env.VITE_MODE,
//   integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
//   tracesSampleRate: 1.0,
//   replaysSessionSampleRate: 0.5,
//   replaysOnErrorSampleRate: 1.0,
// })

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <HeaderProvider>
      <App />
    </HeaderProvider>
  </React.StrictMode>
)
