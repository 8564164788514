import { setAppData } from '@/store/app/reducer'
import { RootState } from '@/store/store'
import React, { ReactNode, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

interface Props {
  children?: ReactNode
}

// Componente abstrado para proteger o acesso a paginas privadas
const PrivatePage: React.FC<Props> = ({ children }): JSX.Element => {
  const authenticatedRedux = useSelector((state: RootState) => state.auth.authenticated)

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(authenticatedRedux)

  useEffect(() => {
    setIsAuthenticated(authenticatedRedux)
    if (!authenticatedRedux) {
      dispatch(setAppData({ modalSignIn: true }))
      navigate('/')
    }
  }, [authenticatedRedux, dispatch, navigate])

  if (isAuthenticated) {
    return <>{children}</>
  } else {
    return <></>
  }
}

export default PrivatePage
