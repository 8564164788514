import { decrypt, encrypt } from '@/lib/crypto'
import { createTransform } from 'redux-persist'

/*const keyString = [
    'accessToken', 
    'name', 
    'email',
    'username',
    'phone'
]*/
const keyNumber = [
  'balance',
  'virtualBalance',
  'depositBalance',
  'decimalBalance',
  'decimalVirtualBalance',
  'decimalDepositBalance',
]
const keyBoolean = ['authenticated']

const encryptTransform = createTransform<string, string>(
  (inboundState, key) => {
    if (inboundState) {
      if (key !== '_persist') {
        return encrypt(`${inboundState}`)
      }

      return inboundState
    }

    return inboundState
  },
  (outboundState, key) => {
    if (outboundState) {
      if (key !== '_persist' && typeof key === 'string') {
        if (keyNumber.includes(key)) {
          return Number(decrypt(outboundState))
        }

        if (keyBoolean.includes(key)) {
          return JSON.parse(decrypt(outboundState))
        }

        return decrypt(outboundState)
      }

      return outboundState
    }

    return outboundState
  }
)

export default encryptTransform
