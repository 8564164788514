import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { fetchStaticData } from '@/service/game.hook';

// Definindo a interface do jogo
interface Game {
  id: number;
  title: string;
  subtitle: string;
  url: string;
  function: string;
  favorite: number;
  provider: string;
  uuid: string;
  gameType: string;
  category: string[];
  backgroundUrl: string;
  gameUrlbcash?: string | null;
  bannerUrlTop10: string | null;
  top?: number | null;
}

// Definindo a interface para o contexto do jogo
interface GameContextType {
  gameCards: Game[];
  newGameCards: Game[];
}

// Criando o contexto com tipo indefinido
const GameContext = createContext<GameContextType | undefined>(undefined);

export const useGameContext = () => {
  const context = useContext(GameContext);
  if (!context) {
    throw new Error('useGameContext must be used within a GameProvider');
  }
  return context;
};

export const GameProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [gameCards, setGameCards] = useState<Game[]>([]);
  const [newGameCards, setNewGameCards] = useState<Game[]>([]);

  useEffect(() => {
    const getGameCards = async () => {
      const { gamesList }  = await fetchStaticData();
      console.log('Fetched data:', gamesList);

      // Verifique se gamesListgamesList está corretamente definido antes de mapear
      if (gamesList.length > 0) {
        const mappedData = gamesList.map((game: any) => ({
          id: game.id,
          title: game.title,
          subtitle: `Game ${game.id}`,
          url: game.banner_url || '',
          function: 'a',
          favorite: game.isEnabled ? 1 : 0,
          provider: game.provider,
          uuid: game.uuid,
          gameType: game.gameType,
          category: game.category,
          backgroundUrl: game.background_url || '',
          gameUrlbcash: game.game_url || '',
          bannerUrlTop10: game.banner_url_top_10 || '/src/assets/top10-banner/top2.png',
          top: game.top !== undefined ? game.top : null,
        }));

        setGameCards(mappedData);

        const topGames = mappedData
          .filter((game: any) => game.top !== null)
          .sort((a: any, b: any) => a.top! - b.top!)
          .map((game: any) => ({
            ...game,
            url: game.bannerUrlTop10,
          }));

        setNewGameCards(topGames);
      }
    };

    getGameCards();
  }, []);

  return (
    <GameContext.Provider value={{ gameCards, newGameCards }}>
      {children}
    </GameContext.Provider>
  );
};
