// rootReducer.ts
import { combineReducers } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { authReducer } from './auth/reducer'
import { userReducer } from './user/reducer'
import { walletReducer } from './wallet/reducer'
import encryptTransform from './crypto-transform'
import { appReducer } from './app/reducer'

const appPersistConfig = {
  key: 'app',
  storage,
  transforms: [encryptTransform],
}

const authPersistConfig = {
  key: 'auth',
  storage,
  transforms: [encryptTransform],
}

const userPersistConfig = {
  key: 'user',
  storage,
  transforms: [encryptTransform],
}

const walletPersistConfig = {
  key: 'wallet',
  storage,
  transforms: [encryptTransform],
}

const rootReducer = combineReducers({
  app: persistReducer(appPersistConfig, appReducer),
  auth: persistReducer(authPersistConfig, authReducer),
  user: persistReducer(userPersistConfig, userReducer),
  wallet: persistReducer(walletPersistConfig, walletReducer),
})

export default rootReducer
