import FormButton from '@/components/atoms/FormButton'
import IconEyeFill from '@/components/atoms/Icons/EyeFill'
import IconEyeSlashFill from '@/components/atoms/Icons/EyeSlashFill'
import { ButtonType } from '@/interfaces/application/buttonType'
import { kify } from '@/lib/currency'
import { setAppData } from '@/store/app/reducer'
import { RootState } from '@/store/store'
import { WalletState } from '@/store/wallet/state'
import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import ModalSaquePix from './ModalSaquePix'
// import { toast } from 'react-toastify'
import walletIcon from '@/assets/icones/walletIcon.png'
import arrowProgressIcon from '@/assets/icones/seta-progress-bar.png'

import coinIcon from '@/assets/icones/coinIcon.png'
import bannerRight from '@/assets/banner/banner-bonus.png'
import useApiService from '@/service/api.hook'

const WalletPage: React.FC = (): JSX.Element => {
  const { cancelWithdrawPending } = useApiService()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [showBalanceNormal, setShowBalanceNormal] = useState<boolean>(true)
  const [showBalanceFrozen, setShowBalanceFrozen] = useState<boolean>(true)
  const [showWithdrawPending, setShowWithdrawPending] = useState<boolean>(true)
  const [showBalancePlus, setShowBalancePlus] = useState<boolean>(true)
  const [wallet, setWallet] = useState<WalletState>()
  const walletRedux = useSelector((state: RootState) => state.wallet)
  const metaBonus = useSelector((state: RootState) => state.user.metaBonus)

  
  const [ruleId, setRuleId] = useState<number>(1)
  const ruleRedux = useSelector((state: RootState) => state.user.ruleId)
  // const [setVerifyEmailDate] = useState<Date>()
  // const verifyEmailDateRedux = useSelector((state: RootState) => state.user.emailVerifiedAt)

  console.log(ruleId)
  useEffect(() => {
    setRuleId(ruleRedux)
  }, [ruleRedux, setRuleId])

  // useEffect(() => {
  //   setVerifyEmailDate(verifyEmailDateRedux)
  // }, [verifyEmailDateRedux, setVerifyEmailDate])

  useEffect(() => {
    setWallet(walletRedux)
  }, [walletRedux, setWallet])

  const openWidthDrawModal = () => {
    // if (ruleId !== 1) {
    //   toast.error('O saque não é permitido no seu tipo de conta')
    //   return
    // } else {
      dispatch(setAppData({ modalWidthdraw: true }))
    // }
  }

  // const handleTypeBonus = (type: string) => {
  //   switch (type) {
  //     case 'deposit':
  //       return 'Deposito'
  //     case 'invite':
  //       return 'Convide e Ganhe'
  //     case 'signature':
  //       return 'Assinatura'
  //     case 'ranking':
  //       return 'Prêmio por Ranking'
  //   }

  // }
  const walletBalanceRedux = useSelector((state: RootState) => state.wallet)
  
  const [walletValue, setWalletValue] = useState<WalletState>({} as WalletState)
  
  useEffect(() => {
    console.log('Wallet balance redux changed:', walletBalanceRedux)
    setWalletValue(walletBalanceRedux)
  }, [walletBalanceRedux])
  console.log('virtual balance wallet: ', walletValue.virtualBalance)

  
  
  // Meta bonus formatado
  const metaBonusLimite = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2
  }).format(metaBonus.limiteBonus);
  
  // Progresso do bonus alcançado
  const metaBonusAlcancado = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2
  }).format(Number(walletValue.bonusAcumulator / 100));
  
  const faltaBonus = metaBonus.limiteBonus - (walletValue.bonusAcumulator / 100)
  // Cálculo do progresso em porcentagem
  const progressoMetaBonusNumero = ((walletValue.bonusAcumulator / 100) / metaBonus.limiteBonus * 100);
  const progressoMetaBonus = progressoMetaBonusNumero >= 100 ? 100 : progressoMetaBonusNumero;

  return (
    <Fragment>
      <ModalSaquePix></ModalSaquePix>
      <div className="flex flex-col w-[100%] ">
        <div className="flex flex-col md:flex-row gap-[20px] w-[100%]">
          <div className="bg-secondaryDarkGrey md:w-[calc(100%)] lg:w-[calc(70%)] p-4 rounded-xl border-pallet2 border">
            <div className="inset-0 p-4 space-y-4">
              <div className="flex justify-between flex-col gap-4">
                <div className="flex flex-col gap-[2px] items-center md:items-start text-center md:text-left">
                  <span className="text-base font-medium opacity-90">
                    Saldo da conta
                    {!showBalanceNormal ? (
                      <IconEyeSlashFill
                        className="w-[23px] cursor-pointer inline"
                        onClick={() => setShowBalanceNormal((i) => !i)}
                      />
                    ) : (
                      <IconEyeFill
                        className="w-[23px] cursor-pointer inline"
                        onClick={() => setShowBalanceNormal((i) => !i)}
                      />
                    )}
                  </span>
                  <span className="text-2xl font-bold">R$ {showBalanceNormal ? wallet?.decimalBalance : '********'}</span>
                </div>
                <div className="flex flex-col gap-[2px] items-center md:items-start text-center md:text-left">
                  <span className="text-base font-medium opacity-90">
                    Saldo congelado
                    {!showBalanceFrozen ? (
                      <IconEyeSlashFill
                        className="w-[23px] cursor-pointer inline"
                        onClick={() => setShowBalanceFrozen((i) => !i)}
                      />
                    ) : (
                      <IconEyeFill
                        className="w-[23px] cursor-pointer inline"
                        onClick={() => setShowBalanceFrozen((i) => !i)}
                      />
                    )}
                  </span>
                  <span className="text-2xl font-bold">
                    R$ {showBalanceFrozen ? wallet?.decimalFronzeBalance : '********'}
                  </span>
                </div>
                <div className="flex flex-col gap-[2px] items-center md:items-start text-center md:text-left">
                  <span className="text-base font-medium opacity-90">
                    Saques pendentes
                    {!showWithdrawPending ? (
                      <IconEyeSlashFill
                        className="w-[23px] cursor-pointer inline"
                        onClick={() => setShowWithdrawPending((i) => !i)}
                      />
                    ) : (
                      <IconEyeFill
                        className="w-[23px] cursor-pointer inline"
                        onClick={() => setShowWithdrawPending((i) => !i)}
                      />
                    )}
                  </span>
                  <span className="text-2xl font-bold">
                    R$ {showWithdrawPending ? kify(wallet?.withdrawPendingBalance || 0) : '********'}
                  </span>
                </div>
                <div className="relative">
                  {wallet?.withdrawPendingBalance ? (
                    <button
                      className="absolute -top-16 right-0 btn bg-red-500 p-2 rounded text-xs font-bold md:text-sm"
                      onClick={async () => {
                        console.log('Clicou')
                        await cancelWithdrawPending()
                      }}
                    >
                      Cancelar
                    </button>
                  ) : null}
                </div>
              </div>

              <div className="flex items-center justify-center md:justify-start gap-4 text-white">
                <FormButton
                  title="DEPOSITAR"
                  onClick={() => navigate('/account/deposit')}
                  type="submit"
                  className={
                    'text-[14px] font-bold w-[150px]  h-[40px] rounded flex items-center bg-accent border-accent to-pallet6 border '
                  }
                  buttonType={ButtonType.Deposit}
                >
                  <div className="mr-1">
                    <img src={walletIcon} className="mr-1" width="20px" />
                  </div>
                </FormButton>

                <FormButton
                  title="SACAR"
                  onClick={openWidthDrawModal}
                  type="submit"
                  className={
                    'text-[14px] font-bold w-[150px]  h-[40px] rounded flex items-center bg-secondaryLightGrey hover:opacity-80 hover:bg-accent'
                  }
                  buttonType={ButtonType.Deposit}
                >
                  <div className="mr-1">
                    <img src={coinIcon} className="mr-1" width="20px" />
                  </div>
                </FormButton>
              </div>
            </div>
          </div>
          <div className="w-[calc(30%-10px)] hidden lg:block">
            <img src={bannerRight} />
          </div>
        </div>
        <div className="bg-secondaryDarkGrey md:w-[calc(100%)] lg:w-[calc(70%-5px)] p-4 mt-4 rounded-xl border-pallet2 border text-center md:text-left">
          <span className="text-base font-bold">
            Dinheiro bônus
            {!showBalancePlus ? (
              <IconEyeSlashFill className="w-[23px] cursor-pointer inline" onClick={() => setShowBalancePlus((i) => !i)} />
            ) : (
              <IconEyeFill className="w-[23px] cursor-pointer inline" onClick={() => setShowBalancePlus((i) => !i)} />
            )}
          </span>
          
          <div className="inset-0 p-4 space-y-4">
            <div className="flex flex-col gap-4 text-center md:text-left">
              <div className="flex flex-col gap-[2px] items-center md:items-start">
                <span className="text-base font-medium opacity-90">
                  Dinheiro Bônus (atinja a meta e reivindique a recompensa)
                </span>
                <span className="text-2xl font-bold">R$ {showBalancePlus ? kify(walletValue.virtualBalance) : '********'}</span>
                <span className="text-xs">Falta R$ {showBalancePlus ? faltaBonus : '********'} para atingir a meta!</span>
              </div>
              <div className="flex flex-col gap-[2px] items-center md:items-start w-full mt-2.5">
                <span className="text-base font-medium opacity-90 text-center w-full mt-2.5">Meta bônus</span>
                <div className="relative w-full h-[24px] mt-2.5 flex">
            <div className="w-full flex h-[4px] rounded-full border border-[#0062D8] bg-[#000C26] relative top-[10px]">
              <div
                className="h-[4px] bg-accent rounded-full absolute top-0 left-0"
                style={{ width: String(progressoMetaBonus)+`%` }}
              ></div>
              <img
                src={arrowProgressIcon}
                className="absolute"
                style={{
                  height: '25px',
                  top: '-12px',  // Ajuste para centralizar verticalmente a imagem com a barra
                  left: String(progressoMetaBonus)+`%`,    // A mesma porcentagem que o width da barra de progresso
                  transform: 'translateX(-50%)' // Para centralizar horizontalmente a imagem
                }}
                alt="Progress Indicator"
              />
            </div>
          </div>

                <div className="w-full h-[14px] flex">
                  <div className="flex-1 text-left">{metaBonusAlcancado}</div>
                  <div className="flex-1 text-right">{metaBonusLimite}</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="pt-4 md:hidden flex justify-center items-center">
          <img src={bannerRight} />
        </div>
      </div>
    </Fragment>
  )
}

export default WalletPage
