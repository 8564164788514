// icon:tiktok | Fontawesome https://fontawesome.com/ | Fontawesome
import * as React from 'react'
type Props = {
  height?: number
  width?: number
}
const IconTiktok: React.FC<Props> = ({ height, width }) => {
  return (
    <svg viewBox="0 0 448 512" fill="currentColor" width={width || 18} height={height || 18}>
      <path d="M448 209.91a210.06 210.06 0 01-122.77-39.25v178.72A162.55 162.55 0 11185 188.31v89.89a74.62 74.62 0 1052.23 71.18V0h88a121.18 121.18 0 001.86 22.17A122.18 122.18 0 00381 102.39a121.43 121.43 0 0067 20.14z" />
    </svg>
  )
}

export default IconTiktok
