import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import { persistStore } from 'redux-persist'
import rootReducer from './rootReducer'
import { AuthState } from './auth/state'
import { UserState } from './user/state'
import { WalletState } from './wallet/state'
import { AppState } from './app/state'

const customizedMiddleware = getDefaultMiddleware({
  serializableCheck: false,
})

const store = configureStore({
  reducer: rootReducer,
  middleware: customizedMiddleware,
})

export const persistor = persistStore(store)

export interface RootState {
  app: AppState
  auth: AuthState
  user: UserState
  wallet: WalletState
}

export default store
