import React from 'react'

const IconVerified: React.FC<React.SVGProps<SVGSVGElement>> = (): JSX.Element => {
  return (
    <svg id="Camada_1" data-name="Camada 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" >
      <defs >
        <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="0%" style={{ stopColor: '#db2777' }} />
          <stop offset="100%" style={{ stopColor: '#db2777' }} />
        </linearGradient>
      </defs>

      <path
        className="cls-1"
        d="m60.26,24.43l-7.42-4.28v-9.13h-9.07l-4.2-7.28-7.57,4.37-7.58-4.37-4.2,7.28h-8.81v8.97l-7.67,4.44,4.37,7.57-4.37,7.57,7.67,4.43v8.35h8.45l4.56,7.91,7.58-4.37,7.57,4.37,4.57-7.91h8.7v-8.5l7.42-4.28-4.37-7.57,4.37-7.57Zm-30.75,19.02l-9.66-9.79,3.17-3.19,6.48,6.47,12.97-12.97,3.19,3.23-16.15,16.25"
        fill="url(#grad1)" // Aplicar o gradiente aqui
      />
    </svg>
  )
}

export default IconVerified
