import React, { useEffect } from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter as Router, useLocation } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { PersistGate } from 'redux-persist/integration/react'
import SplashScreen from './components/atoms/SplashScreen'
import AppRoutes from './routes'
import store, { persistor } from './store/store'
import { GameProvider } from '@/context/GameContext'

// [fonts]
import { DataProvider } from './context/LiveBetting'

const ScrollToTop = () => {
  const location = useLocation();

  useEffect(() => {
    const mainElement = document.getElementById('test-scroll');
    if (mainElement) {
      mainElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [location]);

  return null;
};

const App: React.FC = () => {
  return (
    <main
      className={` text-white bg-[#0f1923] h-full w-full overflow-x-auto lg:overflow-x-hidden`}
      suppressHydrationWarning={true}
    >
      <div id="test-scroll"></div>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        pauseOnFocusLoss
        draggable 
        pauseOnHover
        theme="dark"
      />
      <SplashScreen />
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Router>
            <DataProvider>
              <GameProvider>
                <ScrollToTop />
                <AppRoutes />
              </GameProvider>
            </DataProvider>
          </Router>
        </PersistGate>
      </Provider>
    </main>
  )
}

export default App
