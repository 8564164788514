import { ITranslateDictionary } from './interface'

const dictionarySpanish: Partial<ITranslateDictionary> = {
  customHeader_signin: {
    id: 'customHeader_signin',
    defaultMessage: 'Entrar',
  },
  customHeader_signout: {
    id: 'customHeader_signout',
    defaultMessage: 'Salir',
  },
  customHeader_signup: {
    id: 'customHeader_signup',
    defaultMessage: 'Registro',
  },
}

export default dictionarySpanish
