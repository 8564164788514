import React from 'react'

const Padlock: React.FC = (): JSX.Element => {
  return (
    <svg id="Camada_1" data-name="Camada 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" width="1em" height="1em">
      <path
        fill="currentColor"
        d="m47.86,23.9v-3.23c0-8.76-7.1-15.86-15.86-15.86s-15.86,7.1-15.86,15.86v3.23c-4.12,1.8-6.79,5.87-6.8,10.37v13.59c0,6.25,5.08,11.32,11.33,11.33h22.66c6.25,0,11.32-5.08,11.33-11.33v-13.59c0-4.5-2.67-8.57-6.8-10.37Zm-27.19-3.23c0-6.26,5.07-11.33,11.33-11.33s11.33,5.07,11.33,11.33v2.27h-22.66v-2.27Zm29.45,27.19c0,3.75-3.04,6.8-6.8,6.8h-22.66c-3.75,0-6.8-3.04-6.8-6.8v-13.59c0-3.75,3.04-6.8,6.8-6.8h22.66c3.75,0,6.8,3.04,6.8,6.8v13.59Z"
      />
      <path
        fill="currentColor"
        d="m32,36.53c-1.25,0-2.27,1.01-2.27,2.27v4.53c0,1.25,1.01,2.27,2.27,2.27s2.27-1.01,2.27-2.27v-4.53c0-1.25-1.01-2.27-2.27-2.27Z"
      />
    </svg>
  )
}

export default Padlock
