import { WalletState } from './state'

export const setWalletData = (walletData: Partial<WalletState>) => ({
  type: 'wallet/setWalletData',
  payload: walletData,
})

export const resetWalletData = () => ({
  type: 'wallet/resetWalletData',
})
