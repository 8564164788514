import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import CustomHeader from '@/components/organisms/CustomHeader';
import Footer from '@/components/organisms/Footer';
import ModalSignin from '@/components/organisms/ModalSignin';
import ModalSignup from '@/components/organisms/ModalSignup';
import ModalBanner from '@/components/molecules/Carousel/ModalBanner';
import ModalRecoveryPassword from '@/components/organisms/ModalRecoveryPassword';
import HomePage from '@/pages/home';
import PartnerPage from '@/pages/partner';
import PolicyPage from '@/pages/policy';
import ServiceTermPage from '@/pages/policy/service';
import BonusPage from '@/pages/policy/bonus';
import PrivacyPage from '@/pages/policy/privacy';
import ResponsiblePage from '@/pages/policy/responsible';
import AutoRemovePolicyPage from '@/pages/policy/autoremove';
import PremiumPolicyPage from '@/pages/policy/premium';
import AccountPage from '@/pages/account';
import AccountInfoPage from '@/pages/account/account-info';
import DepositPage from '@/pages/account/deposit';
import PersonalInfoPage from '@/pages/account/personal-info';
import ProofsPage from '@/pages/account/proofs';
import SupportPage from '@/pages/account/support';
import DeactivateAccountPage from '@/pages/account/deactivate-account';
import WalletPage from '@/pages/wallet';
import RankingPage from '@/pages/ranking';
import Gaming from '@/pages/gaming';
import PrivatePage from '@/pages/private-page';
import store, { RootState } from '@/store/store';
import useApiService from '@/service/api.hook';
import { resetAppData } from '@/store/app/reducer';

import { useGameContext } from '@/context/GameContext';
import GameComponent from '@/pages/gaming/generic';
import SearchPage from '@/pages/search';

let _updateUserData;
let loadedPage = false;

document.addEventListener('visibilitychange', () => {
  if (!document.hidden) {
    const authenticatedRedux = store.getState().auth.authenticated;
    if (_updateUserData && authenticatedRedux) {
      _updateUserData();
    }
  }
});

const AppRoutes: React.FC = () => {

  const authenticatedRedux = useSelector((state: RootState) => state.auth.authenticated);
  const { updateUserData } = useApiService();
  _updateUserData = updateUserData;

  const dispatch = useDispatch();
  const { gameCards } = useGameContext();

  useEffect(() => {
    const handleBeforeUnload = () => {
      dispatch(resetAppData());
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [dispatch]);

  if (!loadedPage && authenticatedRedux) {
    updateUserData();
  }

  loadedPage = true;

  return (
    <>
      <ModalSignin />
      <ModalSignup />
      <ModalRecoveryPassword />
      <ModalBanner />
      <Routes>
        <Route
          path="/"
          element={
            <CustomHeader />
          }
        >
          
          <Route index element={<HomePage /> } />
          <Route path="users/reset-password" element={<HomePage />} />
          <Route path="verify" element={<HomePage />} />
          <Route path="search" element={<SearchPage />} />
          <Route path="ranking" element={<RankingPage />} />
          <Route element={
            <PrivatePage>
              <Outlet />
            </PrivatePage>
          }
          >
            <Route path="partner" element={<PartnerPage />} />
            <Route
              path="/account/*"
              element={
                <AccountPage>
                  <Outlet />
                </AccountPage>
              }
            >
              <Route path="info" element={<AccountInfoPage />} />
              <Route path="personal" element={<PersonalInfoPage />} />
              <Route path="proofs" element={<ProofsPage />} />
              <Route path="support" element={<SupportPage />} />
              <Route path="deposit" element={<DepositPage />} />
              <Route path="wallet" element={<WalletPage />} />
              <Route path="disable" element={<DeactivateAccountPage />} />
              <Route path="partner" element={<PartnerPage />} />
            </Route>

            <Route path="wallet" element={<WalletPage />} />
            {
              //<Route path="/history" element={<HistoryPage />} />
            }
          </Route>

          <Route path="*" element={<Navigate to="/" />} />

        </Route>

        <Route
          path="/policy/*"
          element={
            <>
              <CustomHeader />
              <PolicyPage>
                <Outlet />
              </PolicyPage>
              <Footer />
            </>
          }
        >
          <Route path="service" element={<ServiceTermPage />} />
          <Route path="bonus" element={<BonusPage />} />
          <Route path="privacy" element={<PrivacyPage />} />
          <Route path="responsible" element={<ResponsiblePage />} />
          <Route path="autoremove" element={<AutoRemovePolicyPage />} />
          <Route path="premium" element={<PremiumPolicyPage />} />
        </Route>


        <Route
          path="/gaming/*"
          element={
            <>
              <PrivatePage>
                <>
                  <Gaming>
                    <Outlet />
                  </Gaming>
                </>
              </PrivatePage>
            </>
          }
        >
          <React.Fragment>
            {gameCards.map((game) => (
              <Route
                key={game.id}
                path={game.title.toLowerCase().replace(/\s+/g, '-')}
                element={
                  <GameComponent
                    gameUuid={game.uuid}
                    provider={game.provider}
                    backgroundImage={game.backgroundUrl}
                    gameUrlbcash={game.gameUrlbcash}
                  />
                }
              />
            ))}
          </React.Fragment>
        </Route>
      </Routes>
    </>
  );
};

export default AppRoutes;
