import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import IconPesquisar from '@/assets/icones/side-bar-mobile-pesquisar.png'
import IconCassino from '@/assets/icones/side-bar-mobile-cassino.png'
import Iconbcash from '@/assets/icones/side-bar-mobile-maebet.png'
import IconRanking from '@/assets/icones/side-bar-mobile-ranking.png'
import IconRecompensas from '@/assets/icones/side-bar-mobile-recompensas.png'
import BackgroundImage from '@/assets/icones/side-bar-mobile-background.png'
import { useHeader } from '../HeaderContext'

const MobileNavbar: React.FC = () => {
  const { toggleHeaderVisibility, toggleDropdown } = useHeader()
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  const handlePesquisarClick = () => {
    toggleHeaderVisibility()
    toggleDropdown()
    setIsDropdownOpen((prevState) => !prevState)
  }

  const handleLinkClick = () => {
    if (isDropdownOpen) {
      toggleDropdown()
      setIsDropdownOpen(false)
    }
  }

  return (
    <nav
      className="fixed bottom-0 left-0 w-full bg-[#0f1923] text-white flex justify-between items-center px-4 py-2 z-50 lg:hidden bg-cover bg-center"
      style={{ backgroundImage: `url(${BackgroundImage})` }}
    >
      <button onClick={handlePesquisarClick} className="flex flex-col items-center">
        <img src={IconPesquisar} alt="Pesquisar" className="w-4 h-4" />
        <span className="text-[11px]" style={{fontWeight: 'bold' }}>
          Pesquisar
        </span>
      </button>
      <Link to="/search" className="flex flex-col items-center" onClick={handleLinkClick}>
        <img src={IconCassino} alt="Cassino" className="w-4 h-4" />
        <span className="text-[11px]" style={{fontWeight: 'bold' }}>
          Cassino
        </span>
      </Link>
      <Link
        to="/"
        className="flex flex-col items-center"
        style={{ position: 'relative', height: '30px', width: '60px', marginLeft: '18px' }}
      >
        <img
          src={Iconbcash}
          alt="Wallet"
          className="w-4 h-4"
          style={{
            position: 'absolute',
            width: '60px',
            height: '60px',
            top: '-15px',
          }}
        />
      </Link>
      <Link to="/ranking" className="flex flex-col items-center" onClick={handleLinkClick}>
        <img src={IconRanking} alt="Ranking" className="w-4 h-4" />
        <span className="text-[11px]" style={{ fontWeight: 'bold' }}>
          Ranking{' '}
        </span>
      </Link>
      <Link to="/account/partner" className="flex flex-col items-center">
        <img src={IconRecompensas} alt="Recompensas" className="w-3 h-4" />
        <span className="text-[11px]" style={{ fontWeight: 'bold' }}>
          Recompensas
        </span>
      </Link>
    </nav>
  )
}

export default MobileNavbar
