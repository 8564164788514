import React from 'react'

const PrivacyPage: React.FC = (): JSX.Element => {
  return (
    <section className="bg-secondaryDarkGrey  w-full p-4 rounded-xl border-pallet2 border">
      <div className="p-8 w-full mx-auto rounded shadow">
        <h1 className="text-3xl font-bold mb-6">Política de Privacidade</h1>
        <h2 className="text-xl font-bold mb-4">Uso do Site</h2>
        <p className="mb-4">
          Ao usar o site, você concorda em ficar vinculado aos termos desta Política de Privacidade. A Mae.bet pode
          revisar a Política de Privacidade periodicamente e reserva o direito de alterar a Política de Privacidade a
          qualquer momento, a seu critério, publicando tais alterações no site. As alterações entrarão em vigor
          imediatamente após a publicação no site. Seu uso contínuo do site após a publicação constituirá em sua aceitação
          dessas mudanças. Esta Política de Privacidade não se aplicará a sites vinculados ao site e sobre os quais o Mae.bet
          não tem controle.
        </p>
        <h2 className="text-xl font-bold mb-4">Informações Pessoais</h2>
        <p className="ml-4">Os tipos de informações pessoais que a Mae.bet pode coletar e utilizar são:</p>
        <ul className="list-disc ml-8 mb-4">
          <li>Nome</li>
          <li>Nascimento</li>
          <li>Endereço de E-mail</li>
          <li>País de cidadania</li>
          <li>Endereço físico</li>
          <li>Número de identificação</li>
          <li>Identificação emitida pelo governo</li>
          <li>Localização</li>
          <li>Endereço de IP</li>
        </ul>
        <p className="ml-4">A Empresa coleta, ou pode coletar, essas informações por meio de métodos, incluindo:</p>
        <ul className="list-disc ml-8 mb-4">
          <li>Inserção de dados durante os processos de registro da conta e saque.</li>
          <li>Comunicações com clientes.</li>
        </ul>
        <h2 className="text-xl font-bold mb-4">Finalidades da Coleta</h2>
        <p className="ml-4">
          A Mae.bet coletará suas informações pessoais apenas de maneira lícita e justa, para os seguintes fins:
        </p>
        <ul className="list-disc ml-4 mb-4">
          <li>Verificação de identidade (KYC).</li>
          <li>Fornecer ao usuário nossos serviços com excelência e sigilo de dados.</li>
          <li>
            Identificá-lo como usuário e autenticar sua identidade para fins de segurança e cumprimento de nossas obrigações
            legais.
          </li>
          <li>Informá-lo sobre nossos produtos ou serviços que acreditamos ser de seu interesse, via E-mail ou SMS.</li>
          <li>Responder a suas perguntas, comentários ou solicitações.</li>
          <li>
            Determinar a responsabilidade do usuário pelo pagamento de imposto sobre bens e serviços e outros impostos,
            quando aplicável.
          </li>
          <li>Cumprir obrigações contratuais, legais e estatutárias da Mae.bet.</li>
          <li>
            Tomar medidas apropriadas se a Mae.bet tiver motivo para suspeitar que atividades ilegais ou conduta de
            natureza grave foram, estão sendo ou podem ser realizadas em relação às nossas funções e atividades.
          </li>
          <li>Estabelecer, exercer ou defender quaisquer reivindicações legais.</li>
          <li>Gerenciar candidaturas a empregos.</li>
        </ul>
        <p className="ml-4">
          Ao usar a Mae.bet, tornar-se usuário ou optar por fornecer informações pessoais à Mae.bet, você concorda
          com a coleta, uso, processamento e possivelmente divulgação de suas informações pessoais para os fins acima e com
          base nas descrições desta Política de Privacidade.
        </p>
        <h2 className="text-xl font-bold mb-4">Gerenciamento de Suas Informações Pessoais</h2>
        <p>
          A Mae.bet tomará todas as medidas razoáveis para garantir que as informações pessoais que coleta, usa ou
          divulga estejam corretas e sejam armazenadas em um ambiente seguro acessado apenas por pessoas autorizadas.
        </p>
        <p>
          Armazenamos as informações pessoais que recebemos, conforme descrito nesta Política de Privacidade, pelo tempo que
          você usa nossos serviços ou o tempo necessário para cumprir o(s) propósito(s) para o(s) quais foi coletado,
          fornecer nossos serviços, resolver disputas, estabelecer defesas legais, conduzir auditorias, buscar fins
          legítimos de negócios, fazer cumprir nossos acordos e cumprir as leis aplicáveis.
        </p>
        <p>
          A Mae.bet destruirá ou desidentificará permanentemente as informações pessoais que mantemos quando não forem
          mais necessárias para qualquer propósito permitido por serviços legais e operacionais.
        </p>
        <h2 className="text-xl font-bold mb-4">Segurança de Informações Pessoais</h2>
        <p>
          A segurança das informações pessoais é uma consideração crucial. Reconhecemos na Mae.bet que nenhuma
          transmissão de dados pela Internet é completamente invulnerável. Portanto, não podemos assegurar ou garantir a
          completa segurança de qualquer informação que você transmita. É importante notar que qualquer informação
          compartilhada com a Mae.bet é de sua própria responsabilidade.
        </p>
        <p>
          Contudo, após receber sua transmissão, tomaremos medidas apropriadas para proteger suas informações pessoais
          contra possíveis abusos, perdas e acessos não autorizados. Isso inclui a implementação de sistemas seguros com
          senhas e tecnologia SSL.
        </p>
        <p>
          Ao utilizar nossos serviços ou fornecer informações pessoais, você concorda que podemos entrar em contato
          eletronicamente com você sobre questões relacionadas à segurança, privacidade e administração associadas ao uso
          dos serviços. Nossos funcionários, agentes e contratados têm a obrigação de manter a confidencialidade das
          informações pessoais e do comportamento de apostas dos membros.
        </p>
        <p>
          É fundamental destacar que informações publicadas em quadros de avisos ou compartilhadas em plataformas de mídia
          social (como Facebook, Twitter e salas de bate-papo) tornam-se públicas, e a Mae.bet não pode garantir a
          segurança dessas informações.
        </p>
        <p>
          Também levamos a sério a proteção de crianças e não aceitamos informações com a finalidade de criar contas de
          apostas para menores. No entanto, os pais ou responsáveis têm a responsabilidade de monitorar as atividades online
          de suas crianças, incluindo, quando apropriado, o uso de software de filtragem de internet.
        </p>
        <p>
          Lembramos da importância de sempre fazer logout de sua conta ao encerrar o uso do site, especialmente se você
          compartilha um dispositivo com outras pessoas. A segurança e o acesso ao seu próprio computador, dispositivo móvel
          ou qualquer outro dispositivo utilizado para acessar o site são de sua inteira responsabilidade. Em última
          análise, cabe a você manter a confidencialidade de seu nome de usuário, senha e qualquer informação da conta.
          Portanto, tenha cuidado ao usar a Internet e nosso site.
        </p>
        <h2 className="text-xl font-bold mb-4">Marketing com Contato Direto ou Cancelamentos</h2>
        <p>
          De tempos em tempos, podemos utilizar suas informações pessoais para informá-lo sobre novos produtos, serviços ou
          atividades promocionais que a Mae.bet acredita serem de seu interesse ou benefício. Essas comunicações podem
          ser enviadas por e-mail, SMS, telefone ou correio.
        </p>
        <p>
          Se você não deseja mais receber material de marketing ou promoção da Mae.bet, pode solicitar o cancelamento a
          qualquer momento. Basta entrar em contato conosco por e-mail, enviando sua solicitação para{' '}
          <a href="mailto:suporte@Mae.bet" className="text-blue-500">
            suporte@Mae.bet
          </a>
          . Faremos o possível para atender a sua solicitação o mais rápido possível.
        </p>
        <p>
          Além disso, de tempos em tempos, podemos entrar em contato com você para tratar de assuntos relacionados à gestão
          e administração de sua conta na Mae.bet. Essas comunicações podem ser realizadas por qualquer um dos modos de
          contato registrados no momento de seu cadastro como usuário.
        </p>
        <div className="mb-8">
          <h2 className="text-xl font-bold mb-4">Acesso às Informações Pessoais</h2>
          <p>
            Você tem a possibilidade de acessar as informações pessoais coletadas pela Mae.bet seguindo o link "Conta"
            no site ou entrando em contato conosco pelo e-mail{' '}
            <a href="mailto:suporte@Mae.bet" className="text-blue-500">
              suporte@Mae.bet
            </a>
            . Forneceremos a você o acesso às suas informações pessoais da maneira solicitada, sempre que for possível e
            dentro de um período razoável.
          </p>
          <p>
            Caso haja recusa na solicitação ou não seja possível fornecer o acesso da maneira solicitada, nos esforçaremos
            para atender às suas necessidades de outras formas. É importante mencionar que, em determinadas circunstâncias,
            conforme estabelecido nas leis aplicáveis, pode haver situações em que não somos obrigados a conceder acesso às
            suas informações pessoais, especialmente quando isso poderia afetar de forma irrazoável a privacidade de
            terceiros. Se recusarmos o acesso com base nesses motivos, comunicaremos a recusa por escrito, explicando os
            motivos para tal decisão e informando sobre os recursos de reclamação disponíveis para você.
          </p>
        </div>
        <div className="mb-8">
          <h2 className="text-xl font-bold mb-4">Exclusão de Dados Pessoais</h2>
          <p>
            Você tem o direito de solicitar a exclusão de seus dados pessoais caso a Mae.bet não tenha mais uma base
            legal para continuar processando ou retendo essas informações. No entanto, é importante destacar que esse
            direito não é absoluto, uma vez que a Mae.bet pode estar sujeita a obrigações legais que a impeçam de
            atender à sua solicitação de exclusão de dados pessoais. Caso deseje solicitar a exclusão de seus dados
            pessoais, basta enviar um e-mail para{' '}
            <a href="mailto:suporte@Mae.bet" className="text-blue-500">
              suporte@Mae.bet
            </a>
            .
          </p>
        </div>
        <div className="mb-8">
          <h2 className="text-xl font-bold mb-4">Informações de Contato</h2>
          <p>
            Caso surjam dúvidas, necessidade de solicitar acesso ou correção de informações pessoais, ou ainda para
            registrar reclamações relativas ao manuseio de seus dados pessoais, não hesite em entrar em contato conosco.
            Você pode fazê-lo através do seguinte endereço de e-mail:{' '}
            <a href="mailto:suporte@Mae.bet" className="text-blue-500">
              suporte@Mae.bet
            </a>
            , ou utilizar a função de bate-papo disponível no site para falar com nosso suporte ao cliente. Estamos à
            disposição para auxiliá-lo.
          </p>
        </div>
        <div className="mb-8">
          <h2 className="text-xl font-bold mb-4">Fundamentos Legais para o Processamento</h2>
          <p>
            No contexto do Regulamento Geral de Proteção de Dados da União Europeia (UE), a Mae.bet baseia o
            processamento das suas informações pessoais nas seguintes razões legais:
          </p>
          <ul className="list-disc pl-8">
            <li>
              <span className="font-bold">Execução de um Contrato:</span> A Mae.bet realiza o processamento das suas
              informações pessoais para cumprir com os termos de nosso contrato, seja com você diretamente ou com nosso
              cliente.
            </li>
            <li>
              <span className="font-bold">Interesse Legítimo:</span> A Mae.bet processa suas informações pessoais com
              base em nossos interesses legítimos, desde que esses interesses não prejudiquem seus direitos fundamentais e
              liberdades, nem sobreponham-se a eles.
            </li>
            <li>
              <span className="font-bold">Consentimento:</span> Em determinadas situações, a Mae.bet poderá solicitar e
              depender do seu consentimento para o processamento das suas informações pessoais.
            </li>
            <li>
              <span className="font-bold">Cumprimento de Obrigações Legais:</span> A Mae.bet pode processar suas
              informações pessoais quando necessário para cumprir com obrigações legais impostas pela legislação aplicável.
            </li>
          </ul>
        </div>
        <div className="mb-8">
          <h2 className="text-xl font-bold mb-4">Atualizações a esta Política de Privacidade</h2>
          <p>
            Conforme mencionado anteriormente, a Mae.bet pode rever, modificar e atualizar periodicamente esta Política
            de Privacidade para refletir nossas práticas e obrigações em curso. A versão mais recente de nossa Política de
            Privacidade estará disponível em nosso site, localizado em{' '}
            <a href="https://Mae.bet" className="text-blue-500" target="_blank" rel="noopener noreferrer">
              https://Mae.bet
            </a>
            , e quaisquer alterações passarão a vigorar imediatamente após a sua publicação. Portanto, é recomendável que
            você reveja regularmente esta política de privacidade e se familiarize com os seus termos.
          </p>
        </div>
      </div>
    </section>
  )
}

export default PrivacyPage
