// icon:telegram-alt | Unicons https://iconscout.com/unicons | Iconscout
import * as React from 'react'

function IconSpinner(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg id="preloader" xmlns="http://www.w3.org/2000/svg" width="4rem" height="1rem" viewBox="0 0 267 53" {...props}>
      <g id="Group_62" data-name="Group 62" transform="translate(-1882 -1344)">
        <circle id="loading-dot-4" cx="26.5" cy="26.5" r="26.5" transform="translate(2149 1397) rotate(180)" fill="#fff">
          <animate
            attributeName="r"
            begin="0s"
            calcMode="linear"
            dur="0.8s"
            from="15"
            repeatCount="indefinite"
            to="15"
            values="15;18.5;21;26.5;15"
          />
        </circle>
        <circle id="loading-dot-3" cx="21" cy="21" r="21" transform="translate(2061 1391) rotate(180)" fill="#fff">
          <animate
            attributeName="r"
            begin="0s"
            calcMode="linear"
            dur="0.8s"
            from="18.5"
            repeatCount="indefinite"
            to="18.5"
            values="18.5;15;21;26.5;18.5"
          />
        </circle>
        <circle id="loading-dot-2" cx="18.5" cy="18.5" r="18.5" transform="translate(1984 1389) rotate(180)" fill="#fff">
          <animate
            attributeName="r"
            begin="0s"
            calcMode="linear"
            dur="0.8s"
            from="21"
            repeatCount="indefinite"
            to="21"
            values="21;18.5;15;26.5;21"
          />
        </circle>
        <circle id="loading-dot-1" cx="15" cy="15" r="15" transform="translate(1912 1385) rotate(180)" fill="#fff">
          <animate
            attributeName="r"
            begin="0s"
            calcMode="linear"
            dur="0.8s"
            from="26.5"
            repeatCount="indefinite"
            to="26.5"
            values="26.5;21;18.5;15;26.5"
          />
        </circle>
      </g>
    </svg>
  )
}

export default IconSpinner
