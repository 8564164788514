import React from 'react'
import { motion } from 'framer-motion'

interface Props {
  children: React.ReactNode
  contentVisible: boolean
}

const LayoutAnimation: React.FC<Props> = ({ contentVisible, children }): JSX.Element => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{
        opacity: contentVisible ? 1 : 0,
      }}
      transition={{ duration: 1 }}
      className={contentVisible ? 'flex flex-col gap-6 w-full' : 'hidden'}
    >
      {children}
    </motion.div>
  )
}

export default LayoutAnimation
