import { Fragment, useState } from 'react'
import star1 from '@/assets/icones/start-1.png'
import star2 from '@/assets/icones/start-2.png'
import star3 from '@/assets/icones/start-3.png'

const RankingPage: React.FC = (): JSX.Element => {
  const [selectedRanking, setSelectedRanking] = useState('daily')

  const rankings = {
    daily: [
      { rank: '1th', usuario: 'Usuário01', apostado: 'R$200,00', premio: 'R$1.000,00' },
      { rank: '2th', usuario: 'Usuário02', apostado: 'R$500,00', premio: 'R$900,00' },
      { rank: '3th', usuario: 'Usuário03', apostado: 'R$90,00', premio: 'R$750,00' },
      { rank: '4th', usuario: 'Usuário04', apostado: 'R$50,00', premio: 'R$300,00' },
      { rank: '5th', usuario: 'Usuário05', apostado: 'R$50,00', premio: 'R$250,00' },
      { rank: '6th', usuario: 'Usuário06', apostado: 'R$50,00', premio: 'R$200,00' },
      { rank: '7th', usuario: 'Usuário07', apostado: 'R$50,00', premio: 'R$120,00' },
      { rank: '8th', usuario: 'Usuário04', apostado: 'R$50,00', premio: 'R$300,00' },
      { rank: '9th', usuario: 'Usuário05', apostado: 'R$50,00', premio: 'R$250,00' },
      { rank: '10th', usuario: 'Usuário06', apostado: 'R$50,00', premio: 'R$200,00' },
    ],
    weekly: [
      { rank: '1th', usuario: 'UsuárioA', apostado: 'R$300,00', premio: 'R$1.200,00' },
      { rank: '2th', usuario: 'UsuárioB', apostado: 'R$400,00', premio: 'R$800,00' },
      { rank: '3th', usuario: 'UsuárioC', apostado: 'R$100,00', premio: 'R$700,00' },
      // Adicione mais dados conforme necessário
    ],
    monthly: [
      { rank: '1th', usuario: 'Usuário01', apostado: 'R$200,00', premio: 'R$1.000,00' },
      { rank: '2th', usuario: 'Usuário02', apostado: 'R$500,00', premio: 'R$900,00' },
      { rank: '3th', usuario: 'Usuário03', apostado: 'R$90,00', premio: 'R$750,00' },
      { rank: '4th', usuario: 'Usuário04', apostado: 'R$50,00', premio: 'R$300,00' },
      { rank: '5th', usuario: 'Usuário05', apostado: 'R$50,00', premio: 'R$250,00' },
      { rank: '6th', usuario: 'Usuário06', apostado: 'R$50,00', premio: 'R$200,00' },
      { rank: '7th', usuario: 'Usuário07', apostado: 'R$50,00', premio: 'R$120,00' },
      { rank: '8th', usuario: 'Usuário04', apostado: 'R$50,00', premio: 'R$300,00' },
      { rank: '9th', usuario: 'Usuário05', apostado: 'R$50,00', premio: 'R$250,00' },
      { rank: '10th', usuario: 'Usuário06', apostado: 'R$50,00', premio: 'R$200,00' },
    ],
  }

  const handleRankingChange = (ranking: string) => {
    setSelectedRanking(ranking)
  }

  const currentRanking = rankings[selectedRanking]

  return (
    <Fragment>
      <div className="bg-secondaryDarkGrey pt-10 pb-10 pl-2 pr-2 sm:pl-10 sm:pr-10 rounded-lg overflow-x-auto w-full max-w-[98vw]">
        <div className="mb-5 flex ">
<button
  className={`hover:bg-accent hover:opacity-90 flex-grow px-4 py-2 whitespace-nowrap ${
    selectedRanking === 'daily' ? 'bg-accent font-bold' : 'bg-secondaryLightGrey border border-[#324053]'
  } text-white rounded-full`}
  onClick={() => handleRankingChange('daily')}
>
  Diário
</button>
<button
  className={`hover:bg-accent hover:opacity-90 flex-grow px-4 py-2 mx-2 whitespace-nowrap ${
    selectedRanking === 'weekly' ? 'bg-accent font-bold' : 'bg-secondaryLightGrey border border-[#324053]'
  } text-white rounded-full`}
  onClick={() => handleRankingChange('weekly')}
>
  Semanal
</button>
<button
  className={`hover:bg-accent hover:opacity-90 flex-grow px-4 py-2 whitespace-nowrap ${
    selectedRanking === 'monthly' ? 'bg-accent font-bold' : 'bg-secondaryLightGrey border border-[#324053]'
  } text-white rounded-full`}
  onClick={() => handleRankingChange('monthly')}
>
  Mensal
</button>
        </div>
        <div className="grid grid-cols-4 gap-4 text-sm font-semibold text-white mb-2 px-4 min-w-0 sm:min-w-[390px]">
          <span className="text-center">Rank</span>
          <span className="text-center">Usuário</span>
          <span className="text-center">Apostado</span>
          <span className="text-center">Prêmio</span>
        </div>
        <div className="space-y-2 min-w-0 sm:min-w-[390px]">
          {currentRanking.map((item, index) => (
            <div
              key={index}
              className={`hover:opacity-80 hover:bg-secondaryLightGrey grid grid-cols-4 gap-4 p-4 rounded-lg border-[#324053] border-[0.5px] ${
                index % 2 === 0 ? 'bg-secondaryDarkGrey' : 'bg-secondaryDarkGrey'
              } min-w-0 sm:min-w-[405px]`} // Responsive min-width
            >
              <div className="flex items-center justify-center">
                {index === 0 && <img src={star1} alt="Star 1" width={20} height={20} className="mr-2" />}
                {index === 1 && <img src={star2} alt="Star 2" width={20} height={20} className="mr-2" />}
                {index === 2 && <img src={star3} alt="Star 3" width={20} height={20} className="mr-2" />}
                <span className={`text-sm text-white font-light ${index > 2 ? 'mr-6' : ''}`}>{item.rank}</span>
              </div>
              <div className="flex items-center justify-center">
                <span className="text-sm text-secondaryDarkGreyText font-light">{item.usuario}</span>
              </div>
              <div className="flex items-center justify-center">
                <span className="text-sm text-secondaryDarkGreyText font-light">{item.apostado}</span>
              </div>
              <div className="flex items-center justify-center">
                <span className="text-sm text-accent font-bold">{item.premio}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Fragment>
  )
}

export default RankingPage
