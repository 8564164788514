import { AuthState } from './state'

export const setAuthData = (authData: Partial<AuthState>) => ({
  type: 'auth/setAuthData',
  payload: authData,
})

export const resetAuthData = () => ({
  type: 'auth/resetAuthData',
})
