import React, { useEffect, useRef, useState } from 'react'
import { NavLink } from 'react-router-dom'
import IconVerified from '@/components/atoms/Icons/Verified'

// Importando os assets
import homeIcone from '@/assets/icones/home.png'
// import jogarGratisButtonIcone from '@/assets/icones/button-jogar-gratuitamente.png'
import jogarButtonIcone from '@/assets/icones/button-jogar.png'
import fireIcone from '@/assets/icones/fire.png'
import searchButtonIcone from '@/assets/icones/search.png'
import newsIcone from '@/assets/icones/news.png'
import bcashIcone from '@/assets/icones/mae-bet.png'
import emBreveCapa from '@/assets/card-games/EmBreve.webp'

import { CardGames } from '@/interfaces/application/cardGames'
import useDevice from '@/service/device.hook'
import 'swiper/css'
import 'swiper/css/pagination'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper/modules' // Certifique-se de importar o módulo de navegação
import closeIcon from '@/assets/icones/close-desktop.png'

type Props = {
  data: CardGames[]
}

const GamesOriginals: React.FC<Props> = ({ data }): JSX.Element => {
  const [getMaxSlides, setMaxSlides] = useState<number>(7)
  const [cardActive, setCardActive] = useState<number | null>(null)
  const [activeCategory, setActiveCategory] = useState<string>('exclusivos')
  const [additionalItems, setAdditionalItems] = useState<CardGames[]>([])
  const [searchTerm, setSearchTerm] = useState('')
  const inputRef = useRef<HTMLInputElement | null>(null)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const { isMobile } = useDevice()
  const swiperRef = useRef({} as any)
  const maxSlideDesktop = 5
  const maxSlideMobile = 3
  const replaceSpacesWithHyphensInURL = (url: string): string => url.replace(/ /g, '-').toLowerCase()

  useEffect(() => {
    const maxSlides = isMobile ? maxSlideMobile : maxSlideDesktop
    setMaxSlides(maxSlides)
  }, [isMobile, maxSlideDesktop, maxSlideMobile])


  const handleClickOutside = (event: MouseEvent) => {
    if (
      inputRef.current &&
      !inputRef.current.contains(event.target as Node) &&
      !document.querySelector('.modal-class')?.contains(event.target as Node)
    ) {
      setIsModalOpen(false)
    }
  }

  useEffect(() => {
    if (isModalOpen) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isModalOpen])

  const getSlidesPerView = () => {
    return isMobile ? 3.5 : getMaxSlides
  }

  const filterData = () => {
    if (activeCategory === 'todos') {
      return data
    }
    return data.filter((game) => game.category.includes(activeCategory))
  }

  useEffect(() => {
    const filteredData = filterData()
    const additionalItemsCount = 8 - filteredData.length

    const newAdditionalItems = Array.from(
      { length: additionalItemsCount },
      (_, index) =>
        ({
          id: `coming-soon-${index}`,
          title: 'Em breve',
          subtitle: '',
          url: emBreveCapa,
          function: '',
          favorite: 0,
          provider: '',
          uuid: '',
          gameType: '',
          category: [],
          backgroundUrl: '',
          gameUrlbcash: null,
        }) as CardGames
    )

    setAdditionalItems(newAdditionalItems)
  }, [activeCategory, data])

  const filteredData = filterData()

  interface FilterOption {
    key: string;
    label: string;
    icon: string;
    alt: string;
    classNames: string;
    imgClassName: string; // Novo campo para a className da img
    onClick?: () => void;
    link?: string;
  }
  
  const filterOptions: FilterOption[] = [
    {
      key: 'exclusivos',
      label: 'Exclusivos',
      icon: bcashIcone,
      alt: 'Exclusivos',
      classNames: 'rounded-l-full rounded-r-none md:rounded-r-full md:flex-1 md:w-auto text-xs md:text-base px-4',
      imgClassName: 'w-4 h-4', // Adicionando className personalizada para a imagem
    },
    {
      key: 'todos',
      label: 'Todos',
      icon: homeIcone,
      alt: 'Home',
      classNames: 'rounded-none md:rounded-full md:flex-1 md:w-auto text-xs md:text-base px-4',
      imgClassName: 'w-4 h-3', // Adicionando className personalizada para a imagem
      // link: '/search',
    },
    {
      key: 'populares',
      label: 'Populares',
      icon: fireIcone,
      alt: 'Populares',
      classNames: 'rounded-none md:rounded-full md:flex-1 md:w-auto text-xs md:text-base px-4',
      imgClassName: 'w-4 h-4 ', // Adicionando className personalizada para a imagem
    },
    {
      key: 'novidades',
      label: 'Novidades',
      icon: newsIcone,
      alt: 'Novidades',
      classNames: 'rounded-none rounded-r-full rounded-l-none md:rounded-l-full md:flex-1 md:w-auto text-xs md:text-base px-4',
      imgClassName: 'w-4 h-4', // Adicionando className personalizada para a imagem
    },
  ];

  return (
    <div
      className="w-full relative space-y-2 flex flex-col lg:px-4 lg:mt-8 justify-center items-start mt-6"
      id="container-games"
    >
      <div className="text-white w-full flex items-center justify-between gap-2 text-base rounded-full border-[1px] bg-secondaryDarkGrey border-blue-300/30">
        <div className="flex gap-2 pl-1 items-center justify-center ">
          <span className="w-7 h-7">
            <IconVerified />
          </span>
          <span className="flex font-bold text-sm md:text-base">Originais Mae.bet</span>
        </div>

        <div className="flex items-center justify-end text">

          {/* Search Games Label */}
          <div className="relative hidden sm:block">
            <input
              ref={inputRef}
              className="p-2 pl-12 pr-12 border rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500 text-white bg-secondaryDarkGrey"
              placeholder="Encontrar um Jogo"
              type="text"
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value)
                setIsModalOpen(e.target.value !== '') // Abre o modal apenas se o campo não estiver vazio
              }}
            />
            <div className="absolute inset-y-0 left-0 flex items-center pl-1 cursor-pointer">
              <img src={searchButtonIcone} alt="Search Icon" className="h-8 w-8" />
            </div>
            {searchTerm && (
              <div
                className="absolute inset-y-0 right-0 flex items-center pr-1 cursor-pointer"
                onClick={() => {
                  setSearchTerm('')
                  setIsModalOpen(false) // Fechar o modal
                }}
              >
                <img src={closeIcon} alt="Close Icon" className="h-8 w-8" />
              </div>
            )}
          </div>

          {/* Botões <- ->  */}
          <div className="flex h-[30px] md:h-[40px] ">
            <div className="cursor-pointer review-swiper-button-prev rounded-tl-[24px] text-sm rounded-bl-[24px] flex-1 flex items-center bg-accent h-full w-[46px] justify-center border-black">
              <svg
                fill="#fff"
                height={20}
                id="Camada_1"
                data-name="Camada 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 64 64"
              >
                <path
                  className="cls-1"
                  d="m7.15,32L27.48,11.67l6.78,6.78-9.04,9.04h31.62v9.04h-31.62s9.04,9.04,9.04,9.04l-6.78,6.78L7.15,32Z"
                />
              </svg>
            </div>
            <div className="cursor-pointer review-swiper-button-next rounded-tr-[24px] text-sm rounded-br-[24px] flex-1 flex items-center bg-accent h-full w-[46px] justify-center">
              <svg
                fill="#fff"
                height={20}
                className="text-white"
                data-name="Camada 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 64 64"
              >
                <path
                  className="cls-1 text-white"
                  d="m56.85,32l-20.33-20.33-6.78,6.78,9.04,9.04H7.15v9.04h31.62l-9.04,9.04,6.78,6.78,20.33-20.33Z"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>

      {/* Opções filtro */}
      <div
        id="opcoes-filtro"
        className="w-full text-white flex items-center justify-between gap-0 md:gap-4 lg:gap-6 text-base overflow-x-auto py-1"
      >
        {filterOptions.map(({ key, label, icon, alt, classNames, link, imgClassName }) => (
          <button
            key={key}
            className={` flex gap-1 w-full p-2 hover:bg-accent justify-center items-center ${activeCategory === key ? 'bg-accent' : 'bg-secondaryLightGrey'
              } ${classNames}`}
            onClick={() => setActiveCategory(key)}
          >
            <img src={icon} alt={alt} className={imgClassName} />
            {link ? (
              <NavLink to={link} className="">
                {label}
              </NavLink>
            ) : (
              label
            )}
          </button>
        ))}
      </div>

      <div className="container-carrossel">
        <Swiper
          className="swiper-custom"
          modules={[Navigation]}
          spaceBetween={10}
          centeredSlides={false}
          slidesPerView={getSlidesPerView()}
          pagination={false}
          ref={swiperRef}
          onReachBeginning={() => { }}
          onReachEnd={() => { }}
          loop={true}
          autoplay={isMobile ? { delay: 90000 } : {}}
          navigation={{
            nextEl: '.review-swiper-button-next',
            prevEl: '.review-swiper-button-prev',
          }}
        >
          {[...filteredData, ...additionalItems].map((image, index) => (
            <SwiperSlide
              key={index}
              onMouseLeave={() => setCardActive(null)}
              onMouseOver={() => setCardActive(typeof image.id === 'number' ? image.id : null)}
              onTouchStart={() => setCardActive(typeof image.id === 'number' ? image.id : null)}
              className="swiper-slide-custom swipper-jogos"
              style={{ zIndex: cardActive === image.id ? 10 : 1 }}
            >
              <img
                className={`swipper-jogos-img w-full border border-sky-500/50 transition-all cover  ${cardActive && image.id === cardActive && 'opacity-20'
                  } block rounded-2xl`}
                src={image.url}
                alt="Image art of game"
              />
              {typeof image.id === 'number' && image.id === cardActive && image.title !== 'Em breve' && (
                <div className="absolute inset-0 flex flex-col items-center justify-center z-20">
                  <NavLink
                    to={`/gaming/${replaceSpacesWithHyphensInURL(image.title)}`}
                    className="flex items-center justify-center mb-4 nav-link-limited"
                  >
                    <img src={jogarButtonIcone} alt="Jogar" className="button-game" />
                  </NavLink>
                  {/* <NavLink
                    to={`/gaming/${replaceSpacesWithHyphensInURL(image.title)}`}
                    className="flex items-center justify-center nav-link-limited mt-1"
                  >
                    <img src={jogarGratisButtonIcone} alt="Jogar Gratuitamente" className="button-game" />
                  </NavLink> */}
                </div>
              )}
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

    </div>
  )
}

export default GamesOriginals
