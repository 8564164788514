import { UserState } from './state'

export const setUserData = (userData: Partial<UserState>) => ({
  type: 'user/setUserData',
  payload: userData,
})

export const resetUserData = () => ({
  type: 'user/resetUserData',
})
