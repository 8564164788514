import dictionaryEnglish from '@/dictionary/en'
import dictionarySpanish from '@/dictionary/es'
import { ITranslateDictionary, ITranslateKeys, ITranslateMessages } from '@/dictionary/interface'
import dictionaryPortuguese from '@/dictionary/pt'
import { RootState } from '@/store/store'
import { useSelector } from 'react-redux'
import { registerLocale } from 'react-datepicker'
import { es, enUS, ptBR } from 'date-fns/locale'

interface CustomIntl {
  translateMessage: (key: ITranslateKeys, values?: Record<string, any>) => string
  translateDate: (value: Date) => string
  translateNumber: (value: number) => string
  messages: ITranslateMessages
}

const useTranslate = (): CustomIntl => {
  const locale = useSelector((state: RootState) => state.app.locale)

  let dictionary: Partial<ITranslateDictionary>

  switch (locale) {
    case 'en-US':
      dictionary = dictionaryEnglish
      registerLocale('en', enUS)
      break
    case 'es':
      dictionary = dictionarySpanish
      registerLocale('es', es)
      break
    case 'pt-BR':
      dictionary = dictionaryPortuguese
      registerLocale('pt-BR', ptBR)
      break
    default:
      dictionary = dictionaryPortuguese
      registerLocale('pt-BR', ptBR)
  }

  const translateMessage = (key: ITranslateKeys, values?: Record<string, any>): string => {
    const translateItem = dictionary[key] || dictionaryPortuguese[key]
    if (!translateItem) {
      return key
    }

    const messageToUse = translateItem.message || translateItem.defaultMessage

    if (values) {
      return messageToUse.replace(/{\s*([^}\s]+)\s*}/g, (_, p1) => values[p1])
    }

    return messageToUse
  }

  const translateDate = (value: Date): string => {
    const day = String(value.getDate()).padStart(2, '0')
    const month = String(value.getMonth() + 1).padStart(2, '0')
    const year = String(value.getFullYear())

    return locale === 'pt-BR' ? `${day}/${month}/${year}` : `${month}/${day}/${year}`
  }

  const translateNumber = (value: number): string => {
    const formattedValue = value.toFixed(2)
    return locale === 'pt-BR' ? `R$ ${formattedValue}` : `$ ${formattedValue}`
  }

  const messages = Object.keys(ITranslateKeys).reduce(
    (acc, key) => {
      const enumKey = key as keyof typeof ITranslateKeys
      acc[enumKey] = ITranslateKeys[enumKey]
      return acc
    },
    {} as { [key in ITranslateKeys]: ITranslateKeys }
  )

  return {
    translateMessage,
    translateDate,
    translateNumber,
    messages,
  }
}

export default useTranslate
