export function formatCurrency(value: number) {
  return (value / 100).toFixed(2)
}

// export function kify(centsValue: number) {
//   const value = centsValue / 100
//   if (value === 0) {
//     return '0'
//   } else if (value >= 1000000) {
//     return `${(value / 1000000).toFixed(1)}m`
//   } else if (value >= 1000) {
//     return `${(value / 1000).toFixed(1)}k`
//   }
//   return `${value}`
// }


export function kify(centsValue: number): string {
  const value = centsValue;

//   if (value === 0) {
//     return '0.00';
//   } else if (value >= 1000000) {
//     return `${(value / 1000000).toFixed(1).replace('.', '.')}`;
//   } else if (value >= 1000) {
//     return `${(value / 1000).toFixed(1).replace('.', '.')}`;
//   }

  return (value / 100).toFixed(2).replace('.', '.');
}
