import React from 'react'

type Props = {
  height?: number
  width?: number
}

const IconFacebook: React.FC<Props> = ({ height, width }): JSX.Element => {
  return (
    <svg
      id="Camada_1"
      data-name="Camada 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 64"
      fill="white"
      width={width || 18}
      height={height || 18}
    >
      <path d="m36.61,39.73h5.97l1.22-7.6h-7.19v-5.29c0-2.17.81-3.8,4.07-3.8h3.53v-6.92c-1.9-.27-4.07-.54-5.97-.54-6.24,0-10.58,3.8-10.58,10.58v5.97h-6.78v7.6h6.78v19.13h.03l-.16.14c-12.89-2.31-22.65-13.43-22.65-26.86,0-14.92,12.21-27.13,27.13-27.13s27.13,12.21,27.13,27.13c0,13.37-9.7,24.47-22.52,26.83v-19.23Z" />
    </svg>
  )
}

export default IconFacebook
