import React from 'react'

const Coin: React.FC = (): JSX.Element => {
  return (
    <svg id="Camada_1" data-name="Camada 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" width="2em" height="2em">
      <path
        fill="currentColor"
        d="m32,5.59c-14.58,0-26.41,11.82-26.41,26.41s11.82,26.41,26.41,26.41,26.41-11.82,26.41-26.41S46.58,5.59,32,5.59Zm7.59,36.82c-1.15,1.4-2.65,2.35-4.4,2.83-.76.21-1.11.61-1.07,1.4.03.78,0,1.55-.01,2.34,0,.7-.36,1.07-1.04,1.09-.45.01-.89.02-1.34.02-.39,0-.78,0-1.17-.01-.74-.01-1.09-.43-1.09-1.15-.01-.57-.01-1.14-.01-1.71-.01-1.26-.05-1.3-1.26-1.5-1.54-.25-3.06-.6-4.48-1.28-1.11-.54-1.23-.82-.91-1.99.24-.87.48-1.74.75-2.6.2-.63.38-.91.72-.91.2,0,.45.1.78.27,1.56.82,3.23,1.27,4.98,1.49.29.03.59.05.88.05.82,0,1.61-.15,2.38-.49,1.95-.85,2.25-3.1.61-4.44-.55-.46-1.2-.79-1.86-1.09-1.71-.75-3.48-1.31-5.09-2.28-2.61-1.56-4.26-3.71-4.06-6.88.22-3.59,2.25-5.83,5.54-7.02,1.36-.49,1.37-.48,1.37-1.89,0-.48-.01-.96.01-1.45.03-1.07.21-1.25,1.27-1.28h1.24c2.02,0,2.02.09,2.03,2.27q.01,1.61,1.61,1.86c1.23.2,2.39.55,3.52,1.05.62.27.86.71.66,1.37-.28.98-.55,1.97-.86,2.93-.2.59-.38.86-.73.86-.2,0-.43-.08-.74-.23-1.56-.76-3.21-1.13-4.92-1.13-.22,0-.45.01-.66.02-.51.03-1.01.1-1.49.3-1.7.74-1.97,2.61-.52,3.76.73.59,1.56,1,2.42,1.36,1.5.62,3,1.22,4.42,2,4.48,2.5,5.69,8.18,2.51,12.05Z"
      />
    </svg>
  )
}

export default Coin
