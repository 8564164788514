import FormButton from '@/components/atoms/FormButton'
import FormInput from '@/components/atoms/FormInput'
import IconPix from '@/components/atoms/Icons/Pix'
import Modal from '@/components/atoms/Modal'
import { ButtonType } from '@/interfaces/application/buttonType'
import { TPixKey, TWithdraw, pixKeyType } from '@/interfaces/application/widthdrawType'
import useApiService from '@/service/api.hook'
import { setAppData } from '@/store/app/actions'
import { RootState } from '@/store/store'
import { yupResolver } from '@hookform/resolvers/yup'
import React, { useCallback, useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import iconLock from '@/assets/icones/icon-lock.png' // Importe a imagem do cadeado
import { SAQUE_MINIMO } from '@/configs'

const ModalSaquePix: React.FC = (): JSX.Element => {
  const { withdrawPix } = useApiService()

  const dispatch = useDispatch()
  const [selectedType, setSelectedType] = useState<TPixKey>('cpf')

  const modalData = useSelector((state: RootState) => state.app.modalWidthdraw)
  const [ruleId, setRuleId] = useState<number>(1)
  const userCpf = useSelector((state: RootState) => state.user.cpf) // Recupera o CPF do estado Redux

  const ruleRedux = useSelector((state: RootState) => state.user.ruleId)

  console.log(ruleId)
  useEffect(() => {
    setRuleId(ruleRedux)
  }, [ruleRedux, setRuleId])

  const closeModalPix = useCallback(() => {
    dispatch(setAppData({ modalWidthdraw: false }))
  }, [dispatch])

  const schema = Yup.object().shape({
    userDocument: Yup.string(),
    amount: Yup.string()
      .required('Campo obrigatório')
      .test('greaterThan60', 'O valor mínimo de saque é de R$ '+SAQUE_MINIMO, (value) => {
        const numericValue = parseFloat(value.replace('R$', ''))
        return numericValue >= SAQUE_MINIMO
      }),
    pixKey: Yup.string(),
    typePixkey: Yup.mixed<TPixKey>(),
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<TWithdraw>({
    resolver: yupResolver(schema),
  })

  useEffect(() => {
    setValue('typePixkey', selectedType)
  }, [selectedType, setValue])

  const handleFormSubmit: SubmitHandler<TWithdraw> = async (data: TWithdraw): Promise<void> => {
    try {
      data.amount = Number((data.amount as any).replace('R$', '')) * 100
      const pixResponse = await withdrawPix(data)
      const error_saque = !pixResponse.error
      console.log(error_saque)
      if (error_saque) {
        toast.success(pixResponse?.data?.message)
        dispatch(setAppData({ modalWidthdraw: false }))
      } else {
        console.log(pixResponse)
        toast.error(pixResponse?.errorObj?.response?.data?.message)
      }
      // toast.success(pixResponse?.errorObj?.response?.data?.message)
    } catch (error: any) {
      console.error(error)
    }
  }

  return (
    <Modal isOpen={modalData} size="extra-small" closeModal={() => closeModalPix()}>
      <div className="px-1 pb-4 pt-4">
        <p className="text-center uppercase text-white font-bold">SAQUE PIX</p>
        <div className="flex flex-col justify-center items-center gap-1 mb-5">
          <span className="font-thin text-xs text-center  text-gray-400">
            Use o saque pix para transferir valores para sua conta.
          </span>
          <span className=" text-xs text-center font-medium text-red-500">Saque mínimo R$ {SAQUE_MINIMO}.00</span>
        </div>
        <form onSubmit={handleSubmit(handleFormSubmit)} className="mt-4">
          <label className="block text-xs font-thin ml-2 mb-2">
            Como deseja transferir?<span className="text-red-500">*</span>
          </label>
          <div className="lg:flex mb-5">
            {pixKeyType.map((r) => (
              <FormButton
                key={r.value}
                title={r.label}
                onClick={() => setSelectedType(r.value)}
                type="button"
                className={
                  selectedType === r.value
                    ? 'w-[auto] h-[30px] rounded-full mr-1 flex items-center bg-accent border shadow-md font-bold '
                    : 'w-[auto] h-[30px] mr-1 flex items-center rounded-full'
                }
                buttonType={ButtonType.Deposit}
              ></FormButton>
            ))}
          </div>

          <FormInput
            className="mb-5"
            maxLength={11}
            register={register}
            type="text"
            errors={errors}
            value={userCpf}
            readOnly={!!userCpf} // Torna o campo somente leitura se já houver um nome completo armazenado
            icon={userCpf ? <img src={iconLock} alt="Lock Icon" className="h-6 w-6" /> : undefined} // Adiciona o ícone do cadeado somente se houver nome completo
            name="pixKey"
            label={'Será enviado na chave ' + selectedType}
            placeholder={'Digite a chave ' + selectedType}
          />

          <div className="flex items-end">
            <FormInput
              register={register}
              errors={errors}
              required
              type="text"
              name="amount"
              formatType="money-brl"
              placeholder="Exemplo: R$200,00"
              label="Quantia desejada"
            />

            <FormButton
              title="Solicitar saque"
              type="submit"
              className={
                'w-[auto] ml-4 h-[38px] rounded-full mr-4 flex items-center border bg-accent font-bold shadow-md '
              }
              buttonType={ButtonType.Deposit}
            >
              <div className="mr-1">
                <IconPix />
              </div>
            </FormButton>
          </div>
        </form>
        <div
          className="font-bold mt-5 text-center opacity-70 bg-red-100 border border-red-400 text-red-900 px-4 py-3 rounded relative"
          role="alert"
        >
          Atenção! O PIX será efetuado somente após validação da nossa equipe para garantir a segurança da transação e
          autenticidade de quem está solicitando. Não se preocupe, o seu dinheiro será depositado caso todos os dados
          fornecidos estejam corretos.
        </div>
      </div>
    </Modal>
  )
}

export default ModalSaquePix
