import React, { ReactNode } from 'react'
// import ReturnToHomeButton from '@/components/atoms/ReturnToHomeButton'
import '../../styles/gaming.css'

interface Props {
  children?: ReactNode
}

const Gaming: React.FC<Props> = ({ children }): JSX.Element => {
  return (
    <div className="game-screen overflow-hidden">
      <section className="h-full flex flex-col gap-6 lg:flex-col">
        <div className="h-full w-full">
          {/* <ReturnToHomeButton /> */}
          {children}
        </div>
      </section>
    </div>
  )
}

export default Gaming
