import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios'
import { RootState } from '@/store/store'

interface GameComponentProps {
  gameUuid: string
  provider: string
  backgroundImage: string
  gameUrlbcash?: string | null
}

const GameComponent: React.FC<GameComponentProps> = ({
  gameUuid,
  provider,
  backgroundImage,
  gameUrlbcash,
}): JSX.Element => {
  const token = useSelector((state: RootState) => state.auth.accessToken)
  const user_name = useSelector((state: RootState) => state.user.name)
  const user_id = useSelector((state: RootState) => state.user.id)

  const [gameUrl, setGameUrl] = useState('')

  useEffect(() => {
    const initGame = async () => {
      const apiUrl = import.meta.env.VITE_BACKEND_URL || 'https://staging.api.Mae.bet'
      try {

        const response = await axios.post(
          apiUrl+'/slotegrator/init-game',
          {
            game_uuid: gameUuid,
            player_id: user_id,
            player_name: user_name,
            currency: 'EUR',
            session_id: '1',
            return_url: apiUrl,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          }
        )

        if (response.data && response.data.url) {
          setGameUrl(response.data.url)
        } else {
          console.error('URL do jogo não encontrada na resposta da API')
        }
      } catch (error) {
        console.error('Erro ao iniciar o jogo:', error)
      }
    }

    // console.log('teste 123', token)
    // console.log('teste 123', provider)
    console.log('backgroundImage', backgroundImage)
    if (token && provider !== 'bcash') {
      initGame()
    } else {
      if (gameUrlbcash != null) {
        setGameUrl(`${gameUrlbcash}/?token=${token}`)
      }
    }
  }, [token, provider, gameUuid, user_id, user_name])

  return (
    <div
      className="relative z-[999] flex justify-center items-center w-full bg-cover bg-center bg-no-repeat h-full"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <iframe
        title={gameUuid}
        style={{ border: 'none', boxShadow: '0px 0px 26px 8px rgba(0,0,0,0.64)', borderRadius: '0.25rem' }}
        src={gameUrl}
        width="337"
        height="600"
        allowFullScreen
        className="jackpot"
      ></iframe>
    </div>
  )
}

export default GameComponent
