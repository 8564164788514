import FormInput from '@/components/atoms/FormInput'
import React from 'react'
import IconPadlock from '@/components/atoms/Icons/Padlock'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { useForm, SubmitHandler } from 'react-hook-form'
import FormButton from '@/components/atoms/FormButton'
import useApiService from '@/service/api.hook'
import { useSelector } from 'react-redux'
import { RootState } from '@/store/store'
import { useNavigate } from 'react-router'
import { toast } from 'react-toastify'

type FormDataDeleteAccount = {
  obs?: string
  password: string
}

const DeactivateAccountPage: React.FC = (): JSX.Element => {
  const { inactivateUser, signOut } = useApiService()
  const navigate = useNavigate()
  const userId: string = useSelector((state: RootState) => state.user.id)

  const schema = Yup.object().shape({
    obs: Yup.string(),
    password: Yup.string().required('Campo obrigatório'),
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormDataDeleteAccount>({
    resolver: yupResolver(schema),
  })

  const handleFormSubmit: SubmitHandler<FormDataDeleteAccount> = async () => {
    inactivateUser(userId)
      .then(() => {
        toast.success('Conta Excluida com sucesso')
        navigate('/')
        signOut()
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return (
    <div className="bg-secondaryDarkGrey w-full p-4 rounded-xl border-pallet2 border">
      <h1 className="text-xl font-medium pb-2">Inativar minha conta</h1>
      <p className="text-xs opacity-70 w-auto lg:w-[70%]">
        Você pode inativar sua conta ao invés de excluí-la permanentemente. Isso significa que sua conta ficará oculta até
        que você a reative fazendo login novamente.
      </p>
      <form className="mt-8 space-y-4 " onSubmit={handleSubmit(handleFormSubmit)}>
        <div className="flex flex-col gap-1">
          <p className="text-sm">Conte-nos porque você está inativando sua senha:</p>
          <FormInput register={register} errors={errors} name="obs" placeholder="..." />
        </div>
        <div className="flex flex-col lg:flex-row w-full gap-6 items-end">
          <div className="flex flex-col w-full lg:w-3/6">
            <p className="text-sm">Para continuar, insira sua senha novamente:</p>
            <FormInput
              register={register}
              errors={errors}
              name="password"
              type="password"
              placeholder="Sua senha"
              className="w-full"
              icon={<IconPadlock />}
            />
          </div>
          <FormButton type="submit" className="w-full lg:w-3/6 h-[38px] rounded-full mt-4 bg-red-600 shadow-md ">
            <span className="font-medium">INATIVAR</span>
          </FormButton>
        </div>
      </form>
    </div>
  )
}

export default DeactivateAccountPage
