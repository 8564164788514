import { staticPublicAppData } from '@/configs/gamesList'
import axios from 'axios'

const API_URL = import.meta.env.VITE_BACKEND_URL + '/app-data/public'

let isFetching = false

export const fetchData = async () => {
  if (isFetching) {
    console.warn('Fetch already in progress')
    return {
      gamesList: [],
      recentPlays: [],
    }
  }

  isFetching = true

  try {
    const response = await axios.get(API_URL, {
      headers: {
        accept: '*/*',
        'accept-language': 'pt,en-US;q=0.9,en;q=0.8',
        priority: 'u=1, i',
      },
    })

    const gamesList = response.data.games_list || []
    const recentPlays = response.data.recent_plays || []

    return {
      gamesList,
      recentPlays,
    }
  } catch (error) {
    console.error('Error fetching data:', error)
    return {
      gamesList: [],
      recentPlays: [],
    }
  } finally {
    isFetching = false
  }
}

export const fetchStaticData = () => {
  return {
    gamesList: staticPublicAppData.games_list
  }
}