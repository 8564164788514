import FormButton from '@/components/atoms/FormButton'
import FormInput from '@/components/atoms/FormInput'
import IconEmail from '@/components/atoms/Icons/Email'
import IconPadlock from '@/components/atoms/Icons/Padlock'
import Modal from '@/components/atoms/Modal'
import { ButtonType } from '@/interfaces/application/buttonType'
import useApiService from '@/service/api.hook'
import { setAppData } from '@/store/app/actions'
import { RootState } from '@/store/store'
import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect, useRef, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha-enterprise'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import * as Yup from 'yup'

export type FormRecovery = {
  email: string
}

export type FormResetPassword = {
  password: string
  confirmPassword: string
}

const ModalRecoveryPassword: React.FC = (): JSX.Element => {
  const isDevelopment = true //import.meta.env.VITE_MODE === 'development'
  const recaptchKey = import.meta.env.VITE_RECAPTCHA_SITE_KEY
  const recaptchaRef = useRef<ReCAPTCHA>(null)
  const [isVerified, setIsverified] = useState<boolean>(isDevelopment)
  const [hasToken, setHasToken] = useState<boolean>(false)
  const [recoveryToken, setRecoveryToken] = useState<string>('')
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { recoveryPassword, resetPassword, verifyCaptcha } = useApiService()
  const openModal = useSelector((state: RootState) => state.app.modalRecoveryPassword)
  const location = useLocation()

  useEffect(() => {
    if (location.pathname === '/users/reset-password') {
      const params = new URLSearchParams(location.search)
      const token = params.get('token')
      if (token) {
        setRecoveryToken(token)
        setHasToken(true)
      } else {
        setRecoveryToken('')
        setHasToken(false)
      }
    } else {
      setRecoveryToken('')
      setHasToken(false)
    }
  }, [location, setHasToken, setRecoveryToken])

  const openSigin = () => {
    dispatch(setAppData({ modalSignUp: false, modalSignIn: true, modalRecoveryPassword: false }))
  }

  const schema = Yup.object().shape({
    email: Yup.string().required('Campo obrigatório'),
  })

  const schemaReset = Yup.object().shape({
    password: Yup.string()
      .required('Campo obrigatório')
      .min(6, 'A senha deve conter pelo menos 8 caracteres')
      .matches(/(?:[0-9].*){1,}/, 'Deve conter pelo menos 1 números')
      .matches(/(?:[A-Z].*){1,}/, 'Deve conter pelo menos 1 letras maiúsculas'),
    confirmPassword: Yup.string()
      .required('Campo obrigatório')
      .min(6, 'A senha deve conter pelo menos 8 caracteres')
      .matches(/(?:[0-9].*){1,}/, 'Deve conter pelo menos 1 números')
      .matches(/(?:[A-Z].*){1,}/, 'Deve conter pelo menos 1 letras maiúsculas'),
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm<FormRecovery>({
    resolver: yupResolver(schema),
  })

  const FormReset = useForm<FormResetPassword>({
    resolver: yupResolver(schemaReset),
  })
  const closeModalRecovery = () => {
    dispatch(setAppData({ modalRecoveryPassword: false }))
  }

  const handleCaptchaSubmission = async (token: string | null): Promise<void> => {
    try {
      const statusCaptcha = await verifyCaptcha(token)

      if (!statusCaptcha.error) {
        setIsverified(true)
      } else {
        setIsverified(false)
      }
    } catch (error) {
      setIsverified(false)
    }
  }

  const handleFormSubmit: SubmitHandler<FormRecovery> = async (data: FormRecovery): Promise<void> => {
    await recoveryPassword(data.email)
      .then((result) => {
        if (!result.error) {
          reset()
          closeModalRecovery()
          toast.success(`Email enviado com sucesso, Verifique sua caixa de email!`)
        } else {
          toast.error(`Erro ao enviar email: ${result.errorObj.response.data.message}`)
        }
      })
      .catch((err) => {
        console.error(err)
        toast.error(`Erro ao enviar email: ${err.response.data.message}`)
      })
  }

  const handleFormRecovery: SubmitHandler<FormResetPassword> = async (data: FormResetPassword): Promise<void> => {
    await resetPassword(data.password, data.confirmPassword, recoveryToken)
      .then((result) => {
        if (!result.error) {
          FormReset.reset()
          navigate('/')
          openSigin()
          toast.success(`Senha Atualizada!`)
        } else {
          toast.error(`Erro atualizar sua senha: ${result.errorObj.response.data.message}`)
        }
      })
      .catch((err) => {
        console.error(err)
        toast.error(`Erro atualizar sua senha: ${err.response.data.message}`)
      })
  }

  return (
    <Modal isOpen={openModal} size="extra-small" closeModal={() => closeModalRecovery()}>
      {hasToken ? (
        <div className="px-9">
          <p className="text-center uppercase text-white font-medium">NOVA SENHA</p>
          <div className="flex justify-center items-center gap-1 mb-5">
            <span className="font-thin text-xs text-center  text-gray-400">Digite sua nova Senha!</span>
          </div>
          <form onSubmit={FormReset.handleSubmit(handleFormRecovery)}>
            <FormInput
              className="mb-3"
              register={FormReset.register}
              errors={FormReset.formState.errors}
              control={FormReset.control}
              required
              type="password"
              name="password"
              placeholder="Digite uma senha"
              autoComplete="new-password"
              label="Senha"
              icon={<IconPadlock />}
            />

            <FormInput
              className="mb-3"
              register={FormReset.register}
              errors={FormReset.formState.errors}
              control={FormReset.control}
              required
              type="password"
              name="confirmPassword"
              placeholder="Confirme sua senha"
              autoComplete="new-password"
              label="Confirme sua Senha"
              icon={<IconPadlock />}
            />

            {!isDevelopment && (
              <div className="mb-5 flex items-center justify-center">
                <ReCAPTCHA sitekey={recaptchKey} ref={recaptchaRef} onChange={handleCaptchaSubmission} />
              </div>
            )}

            <div className="mb-5">
              <FormButton
                title="Atualizar Senha"
                type="submit"
                className="uppercase font-medium rounded-full"
                buttonType={!isVerified ? ButtonType.Disabled : ButtonType.Confirm}
              ></FormButton>
            </div>
          </form>
        </div>
      ) : (
        <div className="px-9">
          <p className="text-center uppercase text-white font-medium">RECUPERAR SENHA</p>
          <div className="flex justify-center items-center gap-1 mb-5">
            <span className="font-thin text-xs text-center  text-gray-400">
              Insira seu email cadastrado para recuperar seu acesso
            </span>
          </div>
          <form onSubmit={handleSubmit(handleFormSubmit)}>
            <FormInput
              className="mb-3"
              register={register}
              errors={errors}
              control={control}
              required
              icon={<IconEmail />}
              type="text"
              name="email"
              autoComplete="email"
              placeholder="Insira o seu email cadastrado!"
              label="E-mail"
            />

            {!isDevelopment && (
              <div className="mb-5 flex items-center justify-center">
                <ReCAPTCHA sitekey={recaptchKey} ref={recaptchaRef} onChange={handleCaptchaSubmission} />
              </div>
            )}

            <div className="mb-5">
              <FormButton
                title="Recuperar Senha"
                type="submit"
                className="uppercase font-medium rounded-full"
                buttonType={!isVerified ? ButtonType.Disabled : ButtonType.Confirm}
              ></FormButton>
            </div>
          </form>
        </div>
      )}
    </Modal>
  )
}

export default ModalRecoveryPassword
