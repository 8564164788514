import React from 'react'

const IconClose: React.FC<React.SVGProps<SVGSVGElement>> = (props: React.SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg id="Camada_1" data-name="Camada 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" {...props}>
      <path
        fill="currentColor"
        d="m46.77,42.2l-10.19-10.19,10.2-10.2c1.26-1.26,1.26-3.31,0-4.58s-3.31-1.26-4.58,0l-10.2,10.2-10.21-10.21c-1.26-1.26-3.31-1.26-4.58,0s-1.26,3.31,0,4.58l10.21,10.21-10.2,10.2c-1.26,1.26-1.26,3.31,0,4.58s3.31,1.26,4.58,0l10.2-10.2,10.19,10.19c1.26,1.26,3.31,1.26,4.58,0s1.26-3.31,0-4.58Z"
      />
    </svg>
  )
}

export default IconClose
